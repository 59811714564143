import React, { useEffect, useState }   from 'react'
import { useParams, useNavigate }       from 'react-router-dom'
import { Helmet }                       from 'react-helmet'
import axios                            from 'axios'
import Spinner                          from '../components/Spinner'
import { b64Decode }                    from '../utils/utils'

import styles from './ConfirmAccountPage.module.css'


const ConfirmAccountPage = () => {

    const { regUuid }           = useParams();
    const navigate              = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('Activating your account..');

    const title                 = 'Account Activation |  StarGoddess Astrology'
    const metaDescription       = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl            = `${window.location.href}`


    useEffect(() => {

        const confirmAccount = async () => {

            let response;

            try
            {
                setLoading (true);

                const url = (process.env.REACT_APP_NODE_ENV === 'development') ?
                    `${process.env.REACT_APP_API_ENDPOINT}/api/auth/confirmNewAccount?s=${regUuid}` :
                    `${process.env.REACT_APP_BASE_URL}/api/auth/confirmNewAccount?s=${regUuid}` ;

                response = await axios.get(url);

                if (response.status === 201)
                {
                    setMessage('Account confirmed..one moment..');

                    const authCode = b64Decode(response?.data?.authCode);

                    const destinationUrl =
                        `${process.env.REACT_APP_BASE_URL}/auth-success?authCode=${authCode}`;

                    // TODO: figure out why navigate won't work here?
                    window.location.href = destinationUrl;
                }
            }
            catch (error)
            {
                console.error(`⚠ Exception in ConfirmAccountPage:\n${error}\n\n`);

                if (response)
                    console.error(`response:\n${JSON.stringify(response)}\n\n`);

                setMessage('An error occurred while confirming your account.');
            }
            finally
            {
                setLoading (false);
            }
        };

        confirmAccount();

    }, [navigate, regUuid]);


    return (
        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
            <meta name="robots" content="noindex" />
        </Helmet>

        <div className={styles.container}>
            {loading ? <Spinner sizeClass="small" /> : null}
            <p>{message}</p>
        </div>
        </>
    );
};

export default ConfirmAccountPage
