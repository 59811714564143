import React, { useEffect, useRef, useState }   from 'react'
import { useNavigate  }                         from 'react-router-dom'
import { Helmet }                               from 'react-helmet'

import { useAuth }                              from '../auth/AuthContext'

import { startJourney, setJourneyData }         from '../session/journeyState'
import { continueJourney }                      from '../session/journeyLogic'

import BuyNowBox                                from '../components/BuyNowBox'

import Styles from './LoveCompatibilityFunnelPage.module.css'


const LoveCompatibilityFunnelPage = () => {

    const { isAuthenticated }   = useAuth();
    const navigate              = useNavigate();
    const [consent, setConsent] = useState(false);
    const topRef                = useRef(null);

    const title                 = 'Love Compatibility Reading | StarGoddess Astrology'
    const websiteUrl            = `${window.location.href}`
    const metaDescription       = 'Seeking celestial insights on your love interest?  Discover how the stars align your hearts, minds, and souls with our Love Compatibility Reading.'



    const handleConsentChange = (consentValue) => {
        setConsent(consentValue);
    };

    const handleBuyNow = async () => {
        startJourney("love-compatibility-purchase");
        setJourneyData('loveCompatibilityNoRefundConsent', consent);
        await continueJourney(navigate, isAuthenticated);
    };

    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth'});
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:url" content={websiteUrl} />
        {
            window.location.href.includes('love-horoscope-reading') && (
                <link rel="canonical" href={websiteUrl} />
            )
        }
    </Helmet>

    <div ref={topRef}></div>

    <div className={Styles.page}>

        <div className={Styles.container}>

            <h1>Love Compatibility Reading</h1>

            <div className={Styles.textContainer}>
                <h3>What do the stars foretell for your relationship?</h3>

                <p>
                You've met someone new, and the spark is undeniable.
                Are you wondering if they're the one?
                Don’t leave it up to chance—let the StarGoddess guide you!
                </p>

                <p>
                Dive into a personalized Love Compatibility Reading and transform your
                relationship questions into answers.

                Transform your relationship questions into answers today with a personalized
                Love Compatibility Reading — let the universe illuminate your path.
                </p>
            </div>

            <BuyNowBox
                handleSubmit={handleBuyNow}
                onConsentChange={handleConsentChange}
                price="19.99*"
                normalPrice="29.99"
            />

            <div className={Styles.mediaContainer}>
                <iframe
                    src="https://www.youtube.com/embed/N0EwoExEu_g?autoplay=0&loop=0&controls=0&modestbranding=1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="StarGoddess Astrology | Love-Compatibility Reading"
                    frameBorder="0"
                    allowFullScreen
                    loading="lazy"
                    className={Styles.video}></iframe>
            </div>

        </div>

    </div>
    </>
    );
}

export default LoveCompatibilityFunnelPage
