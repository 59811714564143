
const toUtf8 = (data) => {

    /*
    Remember that b64Encode below only works with strings where each character is in the range
    of U+0000 to U+00FF, which is essentially the standard ASCII character set. If you
    need to encode a string with characters outside of this range (like characters in
    different languages or special symbols), you should first encode the string into
    UTF-8. This can be done using encodeURIComponent():
    */

    return encodeURIComponent(data);
};

const utf8ToAscii = (text) => {
  // Decode URI component if necessary
  // const decodedText = decodeURIComponent(text);

  // Replace non-ASCII characters with an empty string
  // eslint-disable-next-line
  return text.replace(/[^\x00-\x7F]/g, "");
};

const b64Encode = (data) => {
    try
    {
        // const decodedText   = decodeURIComponent(data);
        // eslint-disable-next-line
        const cleansed      = data.replace(/[^\x00-\x7F]/g, "");

        return btoa(cleansed);
    }
    catch(err)
    {
        console.log('b64Encode error: ', err.message);
        console.log('for data: ', data);
        return 'b64Encode Error';
    }
}

const b64Decode = (encoded) => {
    try {

        if(! encoded)
            return null;

        return atob(encoded);
    }
    catch(err) {
        console.log('b64Decode error: ', err.message);
        return null;
    }
    // return Buffer.from(encoded, 'base64').toString('utf-8');
};

const rot13 = (str) => {
  return str.replace(/[a-zA-Z]/g, function(char) {
    const base = char <= 'Z' ? 65 : 97;
    return String.fromCharCode(((char.charCodeAt(0) - base + 13) % 26) + base);
  });
}

const obfuscate = (input) => {
  return b64Encode(rot13(input));
}

const deobfuscate = (input) => {
  return rot13(b64Decode(input));
}

const safeParseJson = (data) => {
    try
    {
        return JSON.parse(utf8ToAscii(data));
    }
    catch (err)
    {
        console.error(`⚠ Exception in safeParseJson:\n ${err}\n\n`);
        return null;
    }
}

const readUrlParams = () => {
    const currentUrl    = window.location.href;
    const urlParams     = new URLSearchParams(new URL(currentUrl).search);

    const params = {};

    for (const [key, value] of urlParams.entries()) {
        params[key] = value;
    }

    return params;
}

const capitalize = (str) => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

// -- Object functions ---------------
const isString = (v) => {
    return typeof v === 'string' || v instanceof String;
}

const isObject = (value) => {
    return (value && typeof value === 'object' && !Array.isArray(value))
        ? true : false;
}

const hasAttribute = (object, attributeName) => {
    return object?.hasOwnProperty(attributeName);
}

const enumerateJsonObject = (obj) => {

  if (isObject(obj)) {

    Object.entries(obj).forEach(([key, value]) => {

      console.log(`${key}: ${value}`);
      if (isObject(value)) {
        enumerateJsonObject(value); // Recurse for sub-objects
      }

    });
  }
  else
  {
    console.log('not an object');
  }
};


// -- Date functions ------------------
const toUTCDate = (input) => {
    let date;

    // If the input is a string, try to parse it as a date
    if (typeof input === 'string') {
        date = new Date(input);
    } else if (input instanceof Date) {
        date = input;
    } else {
        throw new Error('Invalid argument: input should be a Date object or a valid date string');
    }

    // Check if the date is valid
    if (isNaN(date)) {
        throw new Error('Invalid date');
    }

    // Return the date in UTC format
    return new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    ));
};

const getNowUTCDate = () => {
  const now = new Date();
  return new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    0,
    0,
    0,
    0
  ));
};

const getNowUTCDateTime = () => {
  const now = new Date();
  return new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  ));
};

const getCurrentUTCDate = () => {
  const now = new Date();
  return new Date(Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  ));
};

const dateFromDMY = (date) => {

    return new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
    ));
}

const getDateAfterDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const getDatePlusMonths = (dtFrom, months) => {
  const result = dateFromDMY(dtFrom);
  result.setMonth(result.getMonth() + months);
  return result;
};

const getDateTimeStr = () => {

    // Get the current date and time in UTC
    const date = new Date();

    // Extract UTC date components
    const utcDay        = date.getUTCDate().toString().padStart(2, '0');
    const utcMonth      = date.toLocaleString('en-GB', { month: 'short' });
    const utcYear       = date.getUTCFullYear();

    // Format date in UTC
    const formattedDate = `${utcDay} ${utcMonth} ${utcYear}`;

    // Extract UTC time components
    const utcHours      = date.getUTCHours().toString().padStart(2, '0');
    const utcMinutes    = date.getUTCMinutes().toString().padStart(2, '0');
    const utcSeconds    = date.getUTCSeconds().toString().padStart(2, '0');

    // Format time in UTC
    const formattedTime = `${utcHours}:${utcMinutes}:${utcSeconds} UTC`;

    // Combine date and time
    return `${formattedDate} ${formattedTime}`;
}

const getDateStr = () => {

    // Get the current date and time in UTC
    const date = new Date();

    // Extract UTC date components
    const utcDay        = date.getUTCDate().toString().padStart(2, '0');
    const utcMonth      = date.toLocaleString('en-GB', { month: 'short' });
    const utcYear       = date.getUTCFullYear();

    // Format date in UTC
    return `${utcDay} ${utcMonth} ${utcYear}`;
}


const formatDate = (date) => {

    try
    {
        if (! date)
            return '';

        const day         = String(date?.getDate()).padStart(2, '0');
        const monthNames  = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month       = monthNames[date.getMonth()];
        const year        = date.getFullYear();

        return `${day}-${month}-${year}`;

    }
    catch(err)
    {
        return '';
    }
};

const formatDateString = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
};

const formatShortDate = (date) => {

    if (typeof date === 'string')
        date = new Date(date);

    if (date instanceof Date && !isNaN(date))
    {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
    }
    else
    {
        return 'Invalid Date';
    }
};

const getStarSignFromDate = (dateString) => {
    const birthdate = new Date(dateString);
    const month     = birthdate.getMonth() + 1;
    const day       = birthdate.getDate();

    if ((month === 1 && day >= 20)  || (month ===  2 && day <= 18)) return 'Aquarius';
    if ((month === 2 && day >= 19)  || (month ===  3 && day <= 20)) return 'Pisces';
    if ((month === 3 && day >= 21)  || (month ===  4 && day <= 19)) return 'Aries';
    if ((month === 4 && day >= 20)  || (month ===  5 && day <= 20)) return 'Taurus';
    if ((month === 5 && day >= 21)  || (month ===  6 && day <= 20)) return 'Gemini';
    if ((month === 6 && day >= 21)  || (month ===  7 && day <= 22)) return 'Cancer';
    if ((month === 7 && day >= 23)  || (month ===  8 && day <= 22)) return 'Leo';
    if ((month === 8 && day >= 23)  || (month ===  9 && day <= 22)) return 'Virgo';
    if ((month === 9 && day >= 23)  || (month === 10 && day <= 22)) return 'Libra';
    if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) return 'Scorpio';
    if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) return 'Sagittarius';
    if ((month === 12 && day >= 22) || (month ===  1 && day <= 19)) return 'Capricorn';

    return 'Unknown';
}

// -- validation functions ---------------

const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;
    const date = new Date(dateString);
    return date.toISOString().startsWith(dateString);
};

const isValidTime = (timeString) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return regex.test(timeString);
};

const console_log = (message) => {
    const now       = new Date();
    const hours     = String(now.getHours()).padStart(2, '0');
    const minutes   = String(now.getMinutes()).padStart(2, '0');
    const seconds   = String(now.getSeconds()).padStart(2, '0');
    console.log(`[${hours}:${minutes}:${seconds}] ${message}`);
};

const console_error = (message) => {
    const now       = new Date();
    const hours     = String(now.getHours()).padStart(2, '0');
    const minutes   = String(now.getMinutes()).padStart(2, '0');
    const seconds   = String(now.getSeconds()).padStart(2, '0');
    console.error(`[${hours}:${minutes}:${seconds}] ${message}`);
};


// -- exports ----------------------------

export {
    toUtf8,
    utf8ToAscii,
    b64Encode,
    b64Decode,
    rot13,
    obfuscate,
    deobfuscate,
    safeParseJson,
    readUrlParams,
    capitalize,
    debounce,
    isString,
    isObject,
    hasAttribute,
    enumerateJsonObject,
    toUTCDate,
    getNowUTCDate,
    getNowUTCDateTime,
    getCurrentUTCDate,
    getDateTimeStr,
    getDateStr,
    dateFromDMY,
    getDateAfterDays,
    getDatePlusMonths,
    getStarSignFromDate,
    formatDate,
    formatDateString,
    formatShortDate,
    isValidDate,
    isValidTime,
    console_log,
    console_error
};
