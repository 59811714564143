import React, { useEffect, useRef, useState }   from 'react'
import { useNavigate  }                         from 'react-router-dom'

import { useAuth }                              from '../auth/AuthContext'

import { startJourney, setJourneyData }         from '../session/journeyState'
import { continueJourney }                      from '../session/journeyLogic'

import BuyNowBox                                from '../components/BuyNowBox'

import Styles from './Tarot10CSFunnelPage.module.css'


const Tarot10CSFunnelPage = () => {

    const { isAuthenticated }   = useAuth();
    const navigate              = useNavigate();
    const [consent, setConsent] = useState(false);
    const topRef                = useRef(null);


    const handleConsentChange = (consentValue) => {
        setConsent(consentValue);
    };

    const handleBuyNow = async () => {
        startJourney('test-purchase');
        setJourneyData('testPurchaseNoRefundConsent', consent);
        await continueJourney(navigate, isAuthenticated);
    };

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
    <>

    <div ref={topRef}></div>

    <div className={Styles.page}>

        <h1>See Your Future Clearly with a Personal Tarot Card Reading</h1>

        <div className={Styles.container}>

            <div className={Styles.textContainer}>

                <h2>Celtic Cross Tarot Reading</h2>
                <p>
                Are you standing at a crossroads, unsure of which path to take? The <em>Celtic Cross Tarot Reading</em> by StarGoddess offers more
                than just answers, it uncovers the deeper forces shaping your journey. Through ten insightful cards, this spread reveals
                not only your current situation but also the hidden influences at play and the clearest way to move forward.
                </p>

                <h3>What the Celtic Cross Reading Uncovers</h3>
                <ul>
                    <li><strong>Clarity & Perspective</strong> You'll see the clear picture of the past, present, and future.</li>
                    <li><strong>Deep Insights</strong> Highlights into your challenges and opportunities.</li>
                    <li><strong>Guidance</strong> The knowledge crucial to navigating your path with confidence.</li>
                </ul>

                <h3>Why Choose this Reading?</h3>
                <p>
                When surface-level advice just won’t cut it, the Celtic Cross gives you the full story. It's not just about answers; it's about
                uncovering the truths that will guide your next step with clarity and confidence. This reading equips you with everything you
                need to navigate your future with purpose and direction.
                </p>

                <p>
                Ready for clarity? Let the Celtic Cross guide you.
                </p>
            </div>

            <BuyNowBox
                handleSubmit={handleBuyNow}
                onConsentChange={handleConsentChange}
                price="19.99*"
                normalPrice="29.99"
            />
        </div>
    </div>
    </>
    );
}

export default Tarot10CSFunnelPage
