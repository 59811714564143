import React, { useEffect, useRef, useState }   from 'react'
import { useNavigate  }                         from 'react-router-dom'

import { useAuth }                              from '../auth/AuthContext'

import { startJourney, setJourneyData }         from '../session/journeyState'
import { continueJourney }                      from '../session/journeyLogic'

import BuyNowBox                                from '../components/BuyNowBox'

import Styles from './Tarot3CSFunnelPage.module.css'


const Tarot3CSFunnelPage = () => {

    const { isAuthenticated }   = useAuth();
    const navigate              = useNavigate();
    const [consent, setConsent] = useState(false);
    const topRef                = useRef(null);


    const handleConsentChange = (consentValue) => {
        setConsent(consentValue);
    };

    const handleBuyNow = async () => {
        startJourney('tarot-3-questions-reading');
        setJourneyData('tarot3CSNoRefundConsent', consent);
        await continueJourney(navigate, isAuthenticated);
    };

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
    <>

    <div ref={topRef}></div>

    <div className={Styles.page}>

        <h1>See Your Future Clearly with a Personal Tarot Card Reading</h1>

        <div className={Styles.container}>

            <div className={Styles.textContainer}>
                <h2>Ask the Tarot Three Questions</h2>

                <p>
                Whether you're seeking clarity on your relationships, career, finances, or life path,
                the 3-Card Tarot Reading from StarGoddess provides focused insights into your questions.  This
                powerful, spread will reveal answers through past influences, present circumstances, and future
                outcomes. Connect with the wisdom of the cards and find the direction you're looking for—your
                destiny awaits in the cards.
                </p>

                <p><em>Ask your questions, then let the tarot guide you..</em></p>

                <div className={Styles.threeCardSpread}>
                    <img src='/img/3-card-spread.webp' alt='Personalised Tarot Reading - three card spread' loading="lazy" />
                </div>

                <p>Act now, and Let the universe illuminate your path.</p>
            </div>

            <BuyNowBox
                handleSubmit={handleBuyNow}
                onConsentChange={handleConsentChange}
                price="19.99*"
                normalPrice="29.99"
            />
        </div>
    </div>
    </>
    );
}

export default Tarot3CSFunnelPage
