import axios                        from 'axios'
import { jwtDecode }                from 'jwt-decode'
import  { isString, b64Decode }     from './utils'


const ACCESS_TOKEN_KEY      = 'accessToken';
const REFRESH_TOKEN_KEY     = 'refreshToken';
const IDENTITY_TOKEN_KEY    = 'identity';


const getAccessToken  = () => localStorage.getItem(ACCESS_TOKEN_KEY);
const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

const saveTokens = (accessToken, refreshToken) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

const clearTokens = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(IDENTITY_TOKEN_KEY);
};

const clearLocalStorage = () => {
    localStorage.clear();
}

const isTokenValid = (token) => {

    if (! token)
        return false;

    const [, payload]       = token.split('.');
    const decodedPayload    = JSON.parse(b64Decode(payload));
    return decodedPayload.exp * 1000 > Date.now();
};

const refreshAccessToken = async () => {

    const refreshToken = getRefreshToken();

    if (! refreshToken)
    {
        return null;
    }

    try
    {
        const response =
            await axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/api/auth/refreshToken`,
                { token: refreshToken }
            );

        const { accessToken } = response.data;
        saveTokens(accessToken, refreshToken);

        return accessToken;
    }
    catch (error)
    {
        clearTokens();
        return null;
    }
}

const getAccountLevel = (token) => {

    try
    {
        if (! token || ! isString(token))
            return null;

        const decodedToken = jwtDecode(token);

        return decodedToken.type;
    }
    catch (error)
    {
        console.error('Invalid token: ', error);
        return null;
    }
};


export {
    getAccessToken,
    getRefreshToken,
    saveTokens,
    clearTokens,
    clearLocalStorage,
    isTokenValid,
    refreshAccessToken,
    getAccountLevel
}
