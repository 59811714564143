import React        from 'react'
import { HashLink } from 'react-router-hash-link'

import LoveHoroscopeBanner  from '../components/LoveHoroscopeBanner'
import Styles               from '../pages/StarsignsPage.module.css'


const StarsignsIndex = () => {

	return (
	<>

    <div className={Styles.page}>
    <div className={Styles.content}>
        <h1>Star Signs of the Zodiac: What does your Star Sign Say about You?</h1>
        <p>Astrology has fascinated humans for centuries, serving as a mystic guidebook for personal and spiritual insight. Consider the words of philosopher Ralph Waldo Emerson: "Astrology is astronomy brought down to Earth and applied to the affairs of men."</p>
        <p>With this perspective, we embark on a journey through the Zodiac, unveiling the distinctive traits and potential that each of the 12 signs holds. From the ambitious Aries to the pragmatic Capricorn, each sign offers a unique window into our world and ourselves.</p>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Aries.png" alt="Aries" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Aries</h2>
                <p><em>(March 21 - April 19)</em></p>
                <p>Aries, the first sign of the Zodiac, is known for its fiery energy and pioneering spirit. These natural leaders are fearless in the face of challenge and are often driven by a desire for excitement and competition. Bold and ambitious, Aries loves to be number one.</p>
                <HashLink smooth to='/star-signs/aries'>Read the Full Guide to Aries</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Taurus.png" alt="Taurus" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Taurus</h2>
                <p><em>(April 20 - May 20)</em></p>
                <p>Grounded and practical, Taurus is the embodiment of perseverance and reliability. Valuing stability above all, they are often incredibly dedicated to their pursuits, whether personal or professional. Taurus finds comfort in the sensual pleasures of life, drawn to good food, music, and lush environments.</p>
                <HashLink smooth to='/star-signs/taurus'>Read all about Taurus</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Gemini.png" alt="Gemini" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Gemini</h2>
                <p><em>(May 21 - June 20)</em></p>
                <p>Gemini’s dual nature makes them versatile and adaptable communicators. Curious and intellectually inclined, they are constantly juggling a variety of passions, hobbies, careers, and social circles. Quick-witted and sociable, Geminis thrive in dynamic, fast-paced environments where they can talk and share ideas.</p>
                <HashLink smooth to='/star-signs/gemini'>Read the full description of Gemini</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Cancer.png" alt="Cancer" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Cancer</h2>
                <p><em>(June 21 - July 22)</em></p>
                <p>Caring Cancer is deeply intuitive and sentimental. They value home, family, and emotional security above all. Often seen as the nurturer of the Zodiac, Cancer is quick to help others and sensitive to the needs of their loved ones. Their loyalty is unparalleled.</p>
                <HashLink smooth to='/star-signs/cancer'>All about Cancer</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Leo.png" alt="Leo" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Leo</h2>
                <p><em>(July 23 - August 22)</em></p>
                <p>Bold, bright, and brimming with confidence, Leos shine like the sun they are ruled by. They love to be in the spotlight and celebrate their unique talents. Generous and warm-hearted, Leos are faithful friends and passionate lovers who often inspire others.</p>
                <HashLink smooth to='/star-signs/leo'>A complete description of Leo</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Virgo.png" alt="Virgo" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Virgo</h2>
                <p><em>(August 23 - September 22)</em></p>
                <p>Methodical and meticulous, Virgos are the problem solvers of the Zodiac. They have a deep sense of humanity and duty to others. Practical in their approach, Virgos are often perfectionists who aren’t afraid to improve skills through diligent and consistent practice.</p>
                <HashLink smooth to='/star-signs/virgo'>A closer look at Virgo</HashLink>
            </div>
        </div>

        <LoveHoroscopeBanner />

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Libra.png" alt="Libra" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Libra</h2>
                <p><em>(September 23 - October 22)</em></p>
                <p>Libra seeks harmony and balance, represented by the scales. They are deeply invested in their personal relationships, often acting as mediators in conflicts. Diplomatic and urbane, Libras have a strong sense of justice and fairness, using their considerable charm to negotiate and smooth over differences.</p>
                <HashLink smooth to='/star-signs/libra'>The complete guide to Libra's</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Scorpio.png" alt="Scorpio" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Scorpio</h2>
                <p><em>(October 23 - November 21)</em></p>
                <p>Intense and enigmatic, Scorpio operates on the principle of transformation. They are focused and determined, delving into the mysteries of life. Scorpios are known for their resilience and powerful emotional depth. With a natural inclination towards the occult and the hidden, they seek profound connections.</p>
                <HashLink smooth to='/star-signs/scorpio'>Scorpio up close and personal</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Sagittarius.png" alt="Sagittarius" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Sagittarius</h2>
                <p><em>(November 22 - December 21)</em></p>
                <p>Adventurous and freedom-loving, Sagittarius is always on a quest for knowledge. This optimistic sign aims to discover the meaning of life through travel, philosophy, and spiritual exploration. Open-minded and enthusiastic, Sagittarians often have a contagious sense of humor and an outgoing personality.</p>
                <HashLink smooth to='/star-signs/sagittarius'>Sagittarius up close and personal</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Capricorn.png" alt="Capricorn" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Capricorn</h2>
                <p><em>(December 22 - January 19)</em></p>
                <p>Capricorn is the planner of the Zodiac, known for a pragmatic outlook and a discipline to achieve their goals. They are masters of self-control and have the potential to be a wise leader or manager. Capricorn values tradition and quality craftsmanship.</p>                        
                <HashLink smooth to='/star-signs/capricorn'>Capricorn: what you need to know</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Aquarius.png" alt="Aquarius"  loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Aquarius</h2>
                <p><em>(January 20 - February 18)</em></p>
                <p>Innovative and eccentric, Aquarius is the visionary of the Zodiac. They are fiercely independent and idealistic, often dedicated to making the world a better place through radical social progress. Aquarius is friendly yet sometimes aloof, thriving on intellectual stimulation.</p>
                <HashLink smooth to='/star-signs/aquarius'>An in-depth look at Aquarius</HashLink>
            </div>
        </div>

        <div className={Styles.starSection}>
            <div className={Styles.imageColumn}>
                <img src="/img/starsign-cards/25/png/Pisces.png" alt="Pisces" loading="lazy" />
            </div>

            <div className={Styles.textColumn}>
                <h2>Pisces</h2>
                <p><em>(February 19 - March 20)</em></p>
                <p>Pisces are the dreamers and mystics of the Zodiac. They are empathetic, artistic, and intuitive, often feeling the emotions of others as their own. With a deep spiritual strength, they seek to connect with the cosmic whole and are known for their forgiving, compassionate nature.</p>
                <HashLink smooth to='/star-signs/pisces'>The lowdown on Pisces</HashLink>
            </div>
        </div>
    </div>

    <LoveHoroscopeBanner />

    </div>
    </>
	);
}

export default StarsignsIndex;
