import React                    from 'react'
import { useParams }            from 'react-router-dom'
import { Helmet }               from 'react-helmet'

import { useAuth }              from '../auth/AuthContext'
import UserDashboard            from '../components/UserDashboard'
import AdminDashboard           from '../components/AdminDashboard'


const DashboardPage = () => {

    // TODO: seperate this into different routes;
    // we already do this isAdmin dance in the ProtectedRoute component.

    const title             = 'Dashboard  |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`

    const { initialTab }    = useParams();
    const { isAdmin }       = useAuth();


    return (
        <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
            <meta name="robots" content="noindex" />
        </Helmet>

        {
            isAdmin ?
                (<AdminDashboard  initialTab={initialTab ? initialTab : null } />) :
                (<UserDashboard   initialTab={initialTab ? initialTab : null } />)
        }
        </>
    );
};

export default DashboardPage
