import React, { useEffect, useRef }     from 'react'
import { useNavigate }                  from 'react-router-dom'
import { Helmet }                       from 'react-helmet'
import axios                            from 'axios'
import axiosInstance                    from '../utils/axiosInstance'
import { useAuth }                      from '../auth/AuthContext'
import useSubscriptionStore             from '../stores/subscriptionStore'
import { continueJourney }              from '../session/journeyLogic'
import Spinner                          from '../components/Spinner'


const AuthSuccessPage = () => {

    const { setToken, isAuthenticated } = useAuth();
    const { setSubscriptionStatus }     = useSubscriptionStore();
    const navigate                      = useNavigate();
    const hasNavigated                  = useRef(false);

    const title             = 'Authentication Success  |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`


    const parseGetParams = () => {

        try
        {
            const params   = new URLSearchParams(window.location.search);
            const authCode = params.get('authCode');

            return { authCode } || null;
        }
        catch(err)
        {
            console.error(`⚠ Exception in AuthSuccessPage::parseGetParams\n${JSON.stringify(err)}\n\n`);
            return null;
        }
    };

    const fetchTokens = async (authCode) => {
        try
        {
            console.log(`authCode: ${authCode}\n`);

            const response = await axios.post(
                `${process.env.REACT_APP_API_ENDPOINT}/api/auth/getTokens`,
                { authCode }
            );

            return response.data;
        }
        catch (err)
        {
            console.error(
                '⚠ Exception in fetchTokens:\n',
                JSON.stringify(err, null, 2),
                '\n\n'
            );

            return null;
        }
    };

    const fetchSubscriptionStatus = async () => {

        try
        {
            const response =
                await axiosInstance.get(`/api/subscription/status`);

            const { isSubscribed } = response.data;
            return isSubscribed;
        }
        catch (err)
        {
            console.error(
                `⚠ Exception in fetchSubscriptionStatus:\n${JSON.stringify(err, null, 2)}\n\n`);
        }

        return false;
    }


    useEffect(() => {

        const start = async () => {

            let signedIn = false;

            if (! isAuthenticated)
            {
                const { authCode } = parseGetParams();

                if (authCode)
                {
                    const tokenData = await fetchTokens(authCode);
                    if (tokenData)
                    {
                        const { accessToken, refreshToken } = tokenData;
                        setToken(accessToken, refreshToken);
                        signedIn = true;

                        const subbed = await fetchSubscriptionStatus();
                        setSubscriptionStatus(subbed);
                    }
                    else
                    {
                        navigate('/');
                    }
                }
                else
                {
                    navigate('/');
                }
            }

            if ((signedIn || isAuthenticated) && !hasNavigated.current)
            {
                hasNavigated.current = true;

                continueJourney(navigate);
            }
        };

        if (hasNavigated.current)
            return;

        start();

    }, [isAuthenticated, setToken, navigate, setSubscriptionStatus]);

    return (
        <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
            <meta name="robots" content="noindex" />
        </Helmet>

        <div className="page">
            <Spinner sizeClass="small" />
            Loading...
        </div>
        </>
    );
};

export default AuthSuccessPage;
