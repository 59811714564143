import React            from 'react'
import { HashLink }     from 'react-router-hash-link'

import Styles from './LoveHoroscopeSection.module.css'


const LoveHoroscopeSection = () => {

    return (
    <>
    <section id="LoveHoroscopeSection" className={Styles.loveHoroscopeSection}>

        <div className={Styles.imageContainer}>
            <img src="/img/lovers.jpg" alt="StarGoddess Love Horoscope Reading" className={Styles.loveImage} loading="lazy" />
        </div>

        <div className={Styles.textContainer}>

            <h3>Is love in the air?  find out with the..</h3>
            <h2>StarGoddess Love Horoscope Reading</h2>

            <p>
            Unlock the secrets of your astrological compatibility and discover the magic
            between you and your partner.
            </p>

            <p>
            Experience the magic of personalized love horoscope readings with StarGoddess!
            Discover the unique strengths that bring you and your partner closer, and uncover
            potential challenges before they arise. Our insights will guide you towards a more
            harmonious and fulfilling relationship, lighting the way to a love that's written
            in the stars.
            </p>

            <div className={Styles.callToAction}>
                <HashLink smooth to='/love-horoscope-reading' className={`${Styles.ctaButton}`}>Find Out More</HashLink>
            </div>

        </div>

    </section>
    </>
    );
};

export default LoveHoroscopeSection;
