import React, { useState, useEffect, useRef, useCallback } from 'react'

import AdminStatsBox            from './AdminStatsBox'
import ClockComponent           from './ClockComponent'
import EmailsByStarsignMtdGraph from './EmailsByStarsignMtdGraph'

import axiosInstance            from '../utils/axiosInstance'

import Styles                   from './AdminDashboard.module.css'



const AdminDashboard = ({ initialTab }) => {

    const topRef = useRef(null);

    const [activeTab, setActiveTab] =
        useState( (initialTab !== null) ? initialTab : 'dashboard');

    const [emailStatsData, setEmailStatsData] = useState({
        title:      'Emails',
        majorLabel: 'Sent This MTD',
        minorLabel: ['Sent Today', 'Sent WTD'],
        dataEndPt:  '/api/stats/mailshot',
        data:       null
    });

    const [subscribersData, setSubscribersData] = useState({
        title:      'Subscribers',
        majorLabel: 'Active',
        minorLabel: ['New Today', 'This Week', 'This Month'],
        dataEndPt:  '/api/stats/subscriptions',
        data:       null
    });

    const [statsData, setStatsData] = useState([
        {
            title:      'Orders',
            majorLabel: 'Orders this Month',
            minorLabel: ['Today', 'This Week'],
            dataEndPt:  '/api/stats/ordersSummary',
            data:       null
        },
        {
            title:      'Order Gen. Failures',
            majorLabel: 'Unresolved Fails',
            minorLabel: ['Res Today', 'Res this Wk', 'Res MTD'],
            dataEndPt:  '/api/stats/orderGenFails',
            data:       null
        }
    ]);

    const fetchData = useCallback(async (dataEndPt) => {
        try
        {
            const response = await axiosInstance.get(dataEndPt);
            return response.data;
        }
        catch (err)
        {
            console.error(`Exception in fetchData:\n${err}\n\n`);
            return null;
        }
    }, []);


    useEffect(() => {
        const fetchAllData = async () =>
        {
            const emailData         = await fetchData(emailStatsData.dataEndPt);
            const subscriberData    = await fetchData(subscribersData.dataEndPt);

            const updatedStats = await Promise.all(statsData.map(async (stat) => {
                const fetchedData = await fetchData(stat.dataEndPt);
                return { ...stat, data: fetchedData };
            }));

            setEmailStatsData((prev)  => ({ ...prev, data: emailData }));
            setSubscribersData((prev) => ({ ...prev, data: subscriberData }));
            setStatsData((prev) => prev.map((stat, i) => ({ ...stat, data: updatedStats[i].data })));
        };

        fetchAllData();
    // eslint-disable-next-line
    }, [fetchData]);

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    const renderContent = () => {

        switch (activeTab)
        {
        case 'dashboard':

            if (statsData[0]?.data && statsData[1]?.data)
            {
                console.log(`statsData (summary):\n${JSON.stringify(statsData[0]?.data, null, 2)}\n\n`);
                console.log(`statsData (genfail):\n${JSON.stringify(statsData[1]?.data, null, 2)}\n\n`);
            }

            return (
            <>
                <h2>email</h2>
                <AdminStatsBox cfg={emailStatsData} color='#fff' bgColor='#add8ff' />
                <EmailsByStarsignMtdGraph graphData={emailStatsData.data} />

                <h2>orders</h2>
                {
                    statsData.map((s, i) => (
                        <AdminStatsBox cfg={s} key={i} color='#fff' bgColor='#e4d6c2' />
                    ))
                }

                <h2>subscriptions</h2>
                <AdminStatsBox cfg={subscribersData} color='#fff' bgColor='#ff8c00' />
            </>
            );

        case 'settings':
        default:
            return <p>Nothing to see here.</p>;
        }
    };


    return (
        <>
        <div ref={topRef}></div>
        <div className={Styles.page}>
            <div className={Styles.dashboardContainer}>
                <div className={Styles.sidebar}>
                    <ClockComponent />
                    <div className={Styles.menuOptions}>
                        <button onClick={() => setActiveTab('dashboard')}>Dashboard</button>
                        <button onClick={() => setActiveTab('settings')}>Settings</button>
                    </div>
                </div>
                <div className={Styles.mainContent}>
                    { renderContent() }
                </div>
            </div>
        </div>
        </>
    );
};

export default AdminDashboard
