import React, { useState, useEffect }   from 'react'
import { useParams, useNavigate }       from 'react-router-dom'
import { Helmet }                       from 'react-helmet'
import axios                            from 'axios'
import Spinner                          from '../components/Spinner'
import styles                           from './UnsubscribePage.module.css'

const UnsubscribePage = () => {
    const { subUuid }               = useParams();
    const [loading, setLoading]     = useState(true);
    const [error, setError]         = useState(null);
    const navigate                  = useNavigate();

    const title             = 'Unsubscribe  |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`


    useEffect(() => {

        const unsubscribe = async () => {
            try
            {
                await axios.get(
                    `${process.env.REACT_APP_API_ENDPOINT}/api/subscription/unsubscribe?s=${subUuid}`);

                setError(null);
            }
            catch (err)
            {
                if (err.response)
                {
                    switch (err.response.status)
                    {
                        case 404:
                            setError('Subscription not found.');
                            break;

                        case 400:
                            setError('Missing or invalid confirmation token.');
                            break;

                        default:
                            setError('An error occurred while trying to unsubscribe.');
                            break;
                    }
                }
                else
                {
                    setError('An error occurred while trying to unsubscribe. Please try again later.');
                }

                setTimeout(() => navigate('/'), 2250);
            }
            finally
            {
                setLoading(false);
            }
        };

        unsubscribe();

    }, [subUuid, navigate]);

    const handleHomeClicked = () => {
        navigate('/');
    };

    return (
        <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
            <meta name="robots" content="noindex" />
        </Helmet>

        <div className={styles.pageContainer}>
            <div className={styles.box}>
                {loading ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner sizeClass="tiny" />
                        <p className={styles.spinnerMessage}>Unsubscribing..</p>
                    </div>
                ) : (
                    <div className={styles.messageContainer}>
                        {error ? (
                            <p className={styles.errorMessage}>{error}</p>
                        ) : (
                            <>
                                <h2 className={styles.title}>You've been successfully unsubscribed.</h2>
                                <p className={styles.message}>You will no longer receive daily horoscope forecasts.</p>
                                <button className={styles.homeButton} onClick={handleHomeClicked}>
                                    Home
                                </button>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
        </>
    );
};

export default UnsubscribePage;
