import React, { useRef, useEffect } from 'react'
import { Helmet }                   from 'react-helmet'
import styles                       from './SigninProviderPage.module.css'
import EmailSigninForm              from '../components/EmailSigninForm'


const SigninProviderPage = () => {

    const title             = 'Sign In |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`

    const topRef            = useRef(null);

    const handleSigninByAuthProvider = (provider) => {
        window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/api/auth/${provider}`;
    };

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);

    return (
        <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
            <meta name="robots" content="noindex" />
        </Helmet>

        <div ref={topRef}></div>
        <div className={styles.page}>

            <h2 className={styles.title}>Sign In to Continue</h2>

            <div className={styles.interface}>


                <div className={styles.container}>
                    <h3>Via OAuth Provider</h3>

                    <button
                        onClick={() => handleSigninByAuthProvider('google')}
                        className={`${styles.oauthButton} ${styles.google}`}
                    >
                        <img src="/img/google-color.svg" width="25px" alt="Google" className={styles.icon} />
                        &nbsp;Authenticate with Google
                    </button>

                </div>

                <div className={styles.container}>
                    <h3>or Via Email</h3>
                    <EmailSigninForm />
                </div>
            </div>

        </div>
        </>
    );
}

export default SigninProviderPage;
