import React from 'react'
import { HashLink }                 from 'react-router-hash-link'
import Styles from './LoveHoroscopeBanner.module.css'


const LoveHoroscopeBanner = () => {

    return (
        <>
        <div className={Styles.banner}>
            <div className={Styles.column}>
                <h2>Is Your Beau - The One?</h2>
                <p>Reveal what the stars say about your love life.</p>

                <div className={Styles.callToAction}>
                    <HashLink smooth to='/love-horoscope-reading'className={Styles.button}>
                        Cast Your Love Horoscope
                    </HashLink>
                </div>
            </div>

            <div className={Styles.column}>
                <HashLink to="/love-horoscope-reading">
                    <div className={Styles.circularMask}>
                        <img
                            src="/img/lovers-at-sunset-beach.jpg"
                            alt="Your Love Horoscope"
                        />
                    </div>
                </HashLink>
            </div>
        </div>
        </>
    );
};

export default LoveHoroscopeBanner
