import React, { useState }              from 'react'
import { useNavigate }                  from 'react-router-dom'

// import axiosInstance                    from '../utils/axiosInstance'
import { useAuth }                      from '../auth/AuthContext'
import { startJourney }                 from '../session/journeyState'
import { continueJourney }              from '../session/journeyLogic'
import useSubscriptionStore             from '../stores/subscriptionStore'

import styles from './SubscribeBox3.module.css'

const SubscribeBox3 = () => {

    const { isAuthenticated }                           = useAuth();
    const [submitting, setSubmitting]                   = useState(false);
    const navigate                                      = useNavigate();
    const { isSubscribed/*, setSubscriptionStatus*/ }   = useSubscriptionStore();

    // const [message, setMessage]                         = useState('');
    // {message && <p className={styles.message}>{message}</p>}

    /*const subscribe = async () => {
        try
        {
            setSubmitting(true);

            await axiosInstance.get('/api/subscription/subscribe');
            setMessage('Thank you so much!');

            setSubscriptionStatus(true);
        }
        catch (error)
        {
            setMessage(`Subscription failed. Please try again later.`);
            console.error('Subscription error:', error);
        }
        finally
        {
            setSubmitting(false);
        }
    };*/

    const handleSubscribe = async () => {

        setSubmitting(true);

        startJourney('mailing-list-subscribe');
        continueJourney(navigate, isAuthenticated);
    };


    if (isAuthenticated && isSubscribed)
    {
        return null;

        /*return (
            <>
            <div className={styles.subscribeBox}>
                <h3>StarGoddess Subscriber</h3>
                <div>💗💖😍💖💗</div>
            </div>
            </>
        );*/
    }

    return (
        <>
        <div className={styles.subscribeBox}>
            <h3>Your free daily horoscope forecast</h3>
            <button onClick={handleSubscribe} disabled={submitting} className={styles.button}>
                {submitting ? 'Subscribing..' : '-> I\'m in! <-'}
            </button>
            <h3>direct to your inbox</h3>
        </div>
        </>
    );
};

export default SubscribeBox3
