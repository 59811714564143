import React        from 'react'
import { Helmet }   from 'react-helmet'
import GlassCard    from '../components/GlassCard'
import Styles       from './PersonalReadingFunnelPage.module.css'


const PersonalReadingFunnelPage = () => {

    const cardData = [
        {
            title:      'Silver Tier',
            subtitle:   'Personalised Horoscope Readings Delivered to your Inbox',
            attributes: ['Daily Horoscope'],
            price:      '$9.99 p/m'
        },

        {
            title:      'Gold Tier',
            subtitle:   'Personalised Horoscope Readings Delivered to your Inbox',
            attributes: ['Daily Horoscope', 'Weekly Horoscope'],
            price:      '$12.49 p/m'
        },

        {
            title:      'Platinum Tier',
            subtitle:   'Personalised Horoscope Readings Delivered to your Inbox',
            attributes: ['Daily Horoscope', 'Weekly Horoscope', 'Monthly Horoscope', 'Annual Horoscope'],
            price:      '$18.49 p/m'
        }
    ];

    const title         = "Personalized Astrology Readings & Horoscopes | StarGoddess Astrology"
    const websiteUrl    = `${window.location.href}`

    return (
    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content="Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights" />
        <link rel="canonical" href={websiteUrl} />
        <meta property="og:url" content={websiteUrl} />
    </Helmet>

    <div id="top"></div>

    <div className={Styles.page}>

        <h1>Personalised Horoscope Readings</h1>

        <img
            src="/img/stars-inverted.jpg"
            alt="starfield"
            loading="lazy"
            className={Styles.backgroundImage}
        />

        <div className={Styles.content}>

            <div className={Styles.cardsContainer}>
                {
                    cardData.map((card, index) => (
                        <GlassCard
                            key={index}
                            title={card.title}
                            subtitle={card.subtitle}
                            attributes={card.attributes}
                            price={card.price}
                        />
                    ))
                }
            </div>

        </div>
    </div>
    </>
    );
};

export default PersonalReadingFunnelPage;
