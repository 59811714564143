import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

const AdminMenu = ( { toggleMenu, handleSignOut } ) => {
    return (
    <>
        <li><HashLink smooth to="/#HeroSection" onClick={toggleMenu} >Home</HashLink></li>
        <li><HashLink smooth to="/dashboard"    onClick={toggleMenu} >Dashboard</HashLink></li>
        <li><Link onClick={handleSignOut}>Sign Out</Link></li>
    </>
    )
}

export default AdminMenu
