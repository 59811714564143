import React, { useState, useEffect, useRef }   from 'react'
import { Helmet }                               from 'react-helmet'

import UserProfile                  from './UserProfile'
import UserOrderHistory             from './UserOrderHistory'
import UserAccountSettings          from './UserAccountSettings'
import Forecast                     from './Forecast'
import ViewReading                  from './ViewReading'
import SubscribeBox3                from './SubscribeBox3'


import Styles from './UserDashboard.module.css'


const UserDashboard = ({initialTab}) => {

    const topRef = useRef(null);

    const [activeTab, setActiveTab] =
        useState( (initialTab !== null) ? initialTab : 'todayForecast');

    const [selectedOrderUuid, setSelectedOrderUuid] = useState('');


    const title             = `Your Personal Dashboard | StarGoddess Astrology`
    const websiteUrl        = `https://www.StarGoddess.co/dashboard`
    const metaDescription   = `Personal Dashboard | Personalized Astrology Readings & Horoscopes | StarGoddess Astrology`


    const handleOrderSelect = (uuid) => {
        setSelectedOrderUuid(uuid);
        setActiveTab('selectedOrder');
    };

    const getForecastMarkup = (d, p) => {
        return (
            <>
            <Forecast duration={d} period={p} />
            <div className={Styles.subContainer}>
                <SubscribeBox3 />
            </div>
            </>
        )
    }

    const renderContent = () => {

        console.log(`renderContent - activeTab: ${activeTab}\n`);

        switch (activeTab)
        {
            case 'yesterdayForecast':
                return getForecastMarkup('day', 'previous');

            case 'todayForecast':
                return getForecastMarkup('day', 'current');

            case 'tomorrowForecast':
                return getForecastMarkup('day', 'next');

            case 'lastWeekForecast':
                return getForecastMarkup('week', 'previous');

            case 'thisWeekForecast':
                return getForecastMarkup('week', 'current');

            case 'nextWeekForecast':
                return getForecastMarkup('week', 'next');

            case 'accountSettings':
                return (
                    <>
                    <UserAccountSettings />
                    </>
                );

            case 'orderHistory':
                return (
                    <>
                    <UserOrderHistory onOrderSelect={handleOrderSelect} />

                    <div className={Styles.notice}>
                        <h3>Waiting for your Order?</h3>

                        <p>If you're waiting for your order, thank you for placing your order with StarGoddess! Your personalized reading is being carefully crafted by our celestial experts. 🌌</p>

                        <p>Please note that this process may take up to 4 hours as we align the stars to provide the most accurate and insightful guidance just for you.</p>

                        <p>As soon as your reading is ready, we’ll send it straight to your inbox. So, sit back, relax, and let the cosmos work their magic.</p>

                        <p>Thank you for your patience and trust in StarGoddess.co!</p>
                        <p>⭐🌟💖🌟⭐</p>
                    </div>
                    </>
                );

            case 'selectedOrder':
                return ( <><ViewReading orderRequestUuid={selectedOrderUuid} /></> );

            default:
                return getForecastMarkup('day', 'current');
        }
    };

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
        </Helmet>

        <div ref={topRef}></div>

        <div className={Styles.page}>
            <div className={Styles.dashboardContainer}>
                <div className={Styles.sidebar}>
                    <UserProfile />
                    <div className={Styles.menuOptions}>
                        <span>Daily Forecast</span>
                        <button onClick={() => setActiveTab('tomorrowForecast')}>Tomorrow</button>
                        <button onClick={() => setActiveTab('todayForecast')}>Today</button>
                        <button onClick={() => setActiveTab('yesterdayForecast')}>Yesterday</button>
                        <span>&nbsp;</span>

                        <span>Weekly Forecast</span>
                        <button onClick={() => setActiveTab('nextWeekForecast')}>Next Week</button>
                        <button onClick={() => setActiveTab('thisWeekForecast')}>This Week</button>
                        <button onClick={() => setActiveTab('lastWeekForecast')}>Last Week</button>
                        <span>&nbsp;</span>

                        <button onClick={() => setActiveTab('orderHistory')}>Order History</button>
                        <button onClick={() => setActiveTab('accountSettings')}>Account Settings</button>
                    </div>
                </div>
                <div className={Styles.mainContent}>
                    { renderContent() }
                </div>
            </div>
        </div>
        </>
    );
};

export default UserDashboard
