import { useEffect }    from 'react'
import { useLocation }  from 'react-router-dom'
import ReactGA          from 'react-ga'
import { b64Decode }    from '../utils/utils'


const GoogleAnalytics = () => {

    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize(b64Decode(`${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`));
        ReactGA.send({ hitType: "pageview", page: location.pathname });

        // helps for debugging tracking events
        // console.log (`google analytics, tracking id: ${trackingId}, page: ${location.pathname}`);
    }, [location]);

    return null;
};

export default GoogleAnalytics;
