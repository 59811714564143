import React, { useState, useRef, useEffect }   from 'react'
import { useNavigate }                          from 'react-router-dom'
import { Helmet }                               from 'react-helmet'
import axiosInstance                            from '../utils/axiosInstance'
import useSubscriptionStore                     from '../stores/subscriptionStore'
import Spinner                                  from '../components/Spinner'
import styles                                   from './ConfirmSubscriptionPage.module.css'


const ConfirmSubscriptionPage = () => {

    const title             = 'Confirm your Subscription  |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`

    const [loading, setLoading]     = useState(true);
    const [error, setError]         = useState(null);
    const { setSubscriptionStatus } = useSubscriptionStore();
    const navigate                  = useNavigate();
    const topRef                    = useRef(null);

    const handleHomeClicked = () => {
        navigate('/');
    }


    useEffect(() => {
        const confirmSubscription = async () => {
            try
            {
                await axiosInstance.get('/api/subscription/subscribe');
                setSubscriptionStatus(true);
            }
            catch (err)
            {
                if (err.response && err.response.status === 409)
                {
                    setError('Your account is already subscribed!');
                    setSubscriptionStatus(true);
                }
                else
                {
                    setError(
                        'An error occurred while confirming your subscription. Please try again later.');
                }
            }
            finally
            {
                setTimeout(() => { setLoading(false); }, 3250);
                setLoading(false);
            }
        };

        confirmSubscription();
    }, [setSubscriptionStatus]);

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={websiteUrl} />
        <meta property="og:url" content={websiteUrl} />
        <meta name="robots" content="noindex" />
    </Helmet>

    <div ref={topRef}></div>
    <div className={styles.pageContainer}>
        <div className={styles.box}>
            { loading ? (
                <div className={styles.spinnerContainer}>
                    <Spinner sizeClass="tiny" />
                    <p className={styles.spinnerMessage}>Subscribing..</p>
                </div>
            ) : (
                <div className={styles.messageContainer}>
                    { error ? (
                        <p className={styles.errorMessage}>{error}</p>
                    ) : (
                        <>
                            <h2 className={styles.title}>Welcome to Your Daily Horoscope Forecast</h2>
                            <p className={styles.message}>You'll receive your horoscope in your inbox each day.</p>
                        </>
                    )}
                    <button className={styles.homeButton} onClick={handleHomeClicked}>
                        Home
                    </button>
                </div>
            )}
        </div>
    </div>
    </>
    );
};

export default ConfirmSubscriptionPage;
