import React, { useState } from 'react'
import styles from './LiteYouTubeEmbed.module.css'


const LiteYouTubeEmbed = ({ w, h, videoId, title }) => {

    const [isIframeLoaded, setIframeLoaded] = useState(false);

    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

    const handleClick = () => {
        setIframeLoaded(true);
    };


  return (
    <div className={styles.liteYoutube} onClick={handleClick} style={{ width: `${w}px`, height: `${h}px` }}>
      { isIframeLoaded ? (
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title={title}
          width={w} height={h}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      ) : (
        <div
          className={styles.placeholder}
          style={{ width: '100%', height: '100%' }}
        >
          <img
            src={thumbnailUrl}
            alt={`Thumbnail for ${title}`}
            loading="lazy"
            width={w}
            height={h}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <span className={styles.playButton}>▶</span>
        </div>
      )}
    </div>
  );
};

export default LiteYouTubeEmbed


/*
            <iframe
                width="507" height="902"
                src="https://www.youtube.com/embed/E1z3gYVkNM0"
                title="StarGoddess Astrology - About Us"
                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                loading="lazy"
                allowFullScreen></iframe>
*/
