import React, { useState, useEffect, useRef }   from 'react'
import { Link, useNavigate }                    from 'react-router-dom'
import { useAuth }                              from '../auth/AuthContext'

import { startJourney, endJourney, processStep } from '../session/journeyState'

import AdminMenu    from '../components/AdminMenu'
import DefaultMenu  from '../components/DefaultMenu'
import UserMenu     from '../components/UserMenu'

import Styles from './Header.module.css'


const Header = () => {

    const { isAuthenticated, isAdmin, resetTokens } = useAuth();

    const isInitialMount            = useRef(true);
    const [scrolled, setScrolled]   = useState(false);
    const [menuOpen, setMenuOpen]   = useState(false);
    const navigate                  = useNavigate();


    const handleSignIn = () => {
        startJourney('sign-in');
        processStep(navigate);
    };

    const handleRegister = () => {
        startJourney('register');
        processStep(navigate);
    };

    const handleSignOut = () => {
        endJourney();
        resetTokens();

        window.location.href = '/';
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };


    useEffect(() => {
        const handleScroll = () => {
            const vh = window.innerHeight / 100;

            if (window.scrollY > 80 * vh)
                setScrolled(true);
            else
                setScrolled(false);
        };

        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAuthenticated]);




    return (
        <header className={`${Styles.header} ${scrolled ? Styles.scrolled : ''}`}>
            <nav className={Styles.menu}>
                <div className={Styles.hamburger} onClick={toggleMenu}>
                    &#9776;
                </div>
                <ul className={Styles.leftMenu}>
                    {!  isAuthenticated && (
                        <DefaultMenu toggleMenu={toggleMenu} />
                    )}
                    {isAuthenticated && !isAdmin && (
                        <>
                            <UserMenu toggleMenu={toggleMenu} handleSignOut={handleSignOut} />
                        </>
                    )}
                    {isAuthenticated && isAdmin && (
                        <>
                            <AdminMenu toggleMenu={toggleMenu} handleSignOut={handleSignOut} />
                        </>
                    )}
                </ul>
                {!isAuthenticated && (
                    <ul className={Styles.rightMenu}>
                        <li><Link onClick={handleRegister}>Register</Link></li>
                        <li><Link onClick={handleSignIn}>Sign In</Link></li>
                    </ul>
                )}
            </nav>

            {menuOpen && (
                <div className={Styles.mobileMenu}>
                    <ul className={Styles.mobileMenuList}>
                        {!isAuthenticated && (
                            <>
                                <DefaultMenu toggleMenu={toggleMenu} />
                            </>
                        )}
                        { isAuthenticated && ! isAdmin && (
                            <>
                                <UserMenu toggleMenu={toggleMenu} handleSignOut={handleSignOut} />
                            </>
                        )}
                        { isAuthenticated && isAdmin && (
                            <>
                                <AdminMenu toggleMenu={toggleMenu} handleSignOut={handleSignOut} />
                            </>
                        )}
                        { ! isAuthenticated && (
                            <>
                                <li><Link onClick={() => { handleRegister(); toggleMenu(); }}>Register</Link></li>
                                <li><Link onClick={() => { handleSignIn(); toggleMenu(); }}>Sign In</Link></li>
                            </>
                        )}
                    </ul>
                </div>
            )}
        </header>
    );
};

export default Header;
