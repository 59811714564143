import React        from 'react'
import StarSignGrid from './StarSignGrid'
import SubscribeBox3 from './SubscribeBox3'

import Styles from './ForecastSection.module.css'

const ForecastSection = () => {
    return (
        <section id="ForecastSection" className={Styles.forecastSection}>
            <div className={Styles.column}>

                <h2>Your free daily Horoscopes</h2>
                <StarSignGrid />
                <SubscribeBox3 />
            </div>
        </section>
    );
};

export default ForecastSection;
