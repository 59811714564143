import React, { useState }  from 'react'
import { HashLink }         from 'react-router-hash-link'
import Spinner              from '../components/Spinner'

import Styles from './BuyNowBox.module.css'

const BuyNowBox = ({ handleSubmit, onConsentChange, price, normalPrice }) => {

    const [consent, setConsent] = useState(false);
    const [loading, setLoading] = useState(false);

    const onClick = async () => {
        setLoading(true);
        await handleSubmit();
    };

    const handleConsentChange = (e) => {
        const consentValue = e.target.checked;
        setConsent(consentValue);

        if (onConsentChange)
            onConsentChange(consentValue);
    };


    return (
    <>
    <div className={Styles.buyNowContainer}>

        <div className={Styles.priceContainer}>
            <div className={Styles.priceTag}>
              <span className={Styles.amount}>${price}</span>
            </div>
            <div className={Styles.priceDescription}>
              *(launch price), normally ${normalPrice}
            </div>
        </div>

        <div className={Styles.agreementContainer}>
            <input
                type="checkbox"
                checked={consent}
                onChange={handleConsentChange}
                id="no-refunds"
                className={Styles.checkbox}
            />
            <label htmlFor="no-refunds" className={Styles.checkboxLabel}>
                I consent to no refunds on this product **
            </label>
        </div>

        <button
            className={Styles.submitButton}
            onClick={onClick}
            disabled={loading || !consent}
        >
            {loading && <Spinner sizeClass="tiny" />}
            Buy Now
        </button>

        <div className={Styles.smallPrint}>
            <HashLink smooth to='/refund-policy'>**more info</HashLink>
        </div>

    </div>
    </>
    );
};

export default BuyNowBox
