import React            from 'react'
//import { HashLink }     from 'react-router-hash-link'
import Styles           from './PersonalReadingSection.module.css'

const PersonalReadingSection = () => {


    const handleFindOutMore = (e) => {
        // <HashLink smooth to='/personalised-readings#top' className={`${Styles.ctaButton}` }>Coming Soon</HashLink>
        e.preventDefault();
        return;
    }


    return (
    <>
    <section id="PersonalReadingSection" className={Styles.personalReadingSection}>

        <img    src="/img/beach-woman.jpg"
                alt="looking ahead with astrology"
                loading="lazy"
                className={Styles.backgroundImage}
        />

        <div className={Styles.textContainer}>
            <h2>Get Personalised Horoscope Readings</h2>
            <h3>..delivered to your inbox</h3>

            <p>
            Personalized horoscope readings are tailored to your place, time and date of birth
            and regularly delivered directly to your inbox.
            </p>

            <p>
            Get insights tailored specifically for you and stay connected with the stars.
            </p>

            <button className={Styles.ctaButton} onClick={handleFindOutMore}>Coming Soon</button>
        </div>

    </section>
    </>
    )
}

export default PersonalReadingSection
