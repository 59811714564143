import React, { useState, useCallback, useEffect }  from 'react'
import { useNavigate }                              from 'react-router-dom'
import { Helmet }                                   from 'react-helmet'

import DatePicker                                   from 'react-date-picker'
import TimePicker                                   from 'react-time-picker'

import axios                                        from 'axios'
import axiosInstance                                from '../utils/axiosInstance'

//import { useAuth }                                  from '../auth/AuthContext'

// import { processStep }                              from '../session/journeyState'
import { continueJourney }                          from '../session/journeyLogic'

import CustomButton                                 from '../components/CustomButton'
import { getStarsign }                              from '../utils/astro'
import { debounce, b64Encode }                      from '../utils/utils'
import { emptyIdent, validateIdentity }             from '../json/Identity'

import styles from './PartnerInfoPage.module.css'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-time-picker/dist/TimePicker.css'


const PartnerInfoPage = () => {

    const navigate = useNavigate();

    const title             = 'Your Partner\'s Personal Information  |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`

    // const { isAuthenticated }                       = useAuth();

    const [currentStep, setCurrentStep]             = useState(1);
    const [identity, setIdentity]                   = useState({...emptyIdent});

    const [dateOfBirth, setDateOfBirth]             = useState(new Date());
    const [timeOfBirth, setTimeOfBirth]             = useState('12:00');

    const [placeSuggestions, setPlaceSuggestions]   = useState([]);
    const [query, setQuery]                         = useState('');
    const [error, setError]                         = useState('');
    const [errors, setErrors]                       = useState({});

    const [isStep1Valid, setIsStep1Valid]           = useState(false);
    const [isStep2Valid, setIsStep2Valid]           = useState(false);
    const [isStep3Valid, setIsStep3Valid]           = useState(false);




    const fetchPlaceSuggestions = async (query) => {
        try
        {
            const response = await axios.get(
                `https://nominatim.openstreetmap.org/search?format=json&q=${query}`
            );
            setPlaceSuggestions(response.data);
        }
        catch (error)
        {
            console.error('Error fetching place suggestions:', error);
        }
    };

    // eslint-disable-next-line
    const debounceFetchPlaceSuggestions = useCallback(
        debounce(async (query) => {
            await fetchPlaceSuggestions(query);
        }, 300),
        []
    );

    const validateStep1 = (firstName, lastName, sex) => {
        setIsStep1Valid((firstName !== '' && lastName !== '' && sex !== '') ? true:false);
    };


    const handlePlaceSelect = (place) => {
        setIdentity({
            ...identity,
            pob: {
                name: place.display_name,
                lat: place.lat,
                long: place.lon
            }
        });
        setPlaceSuggestions([]);

        setIsStep2Valid(true);
    };

    const handleUpdateBirthdate = (date, time) => {

        const today             = new Date();
        const eighteenYearsAgo  = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
        const [hours, minutes]  = time.split(':');

        const utcDate =
                new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes));

        if (utcDate <= eighteenYearsAgo)
        {
            setDateOfBirth(utcDate);
            setTimeOfBirth(time);

            setIdentity({
                ...identity,
                dob:        utcDate.toISOString(),
                starsign:   getStarsign(utcDate),
            });

            setIsStep3Valid(true);
        }
        else
        {
            setIsStep3Valid(false);
        }
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = async (event) => {

        event.preventDefault();

        const ident = {
            ...identity,
            name:       `${identity.firstName} ${identity.surName}`,
            firstName:  `${identity.firstName}`,
            surName:    `${identity.surName}`,
            isComplete: true
        };

        setIdentity(ident);

        const validationErrors = validateIdentity(ident);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setError("Please complete all required fields.");
            return;
        }

        try
        {
            await axiosInstance.post('/api/id/savePartnerIdentity', {
                identity: b64Encode(JSON.stringify(ident))
            });

            // processStep(navigate);
            continueJourney(navigate);

        }
        catch (err)
        {
            setError('An error occurred while saving your information.');
            console.error(err);
        }
    };


    useEffect(() => {
        if (query) {
            debounceFetchPlaceSuggestions(query);
        }
    }, [query, debounceFetchPlaceSuggestions]);



    return (
        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
            <meta name="robots" content="noindex" />
        </Helmet>

        <div className={styles.page}>

            <h2>Add your Love Interest's Details</h2>

            { error && <div className={styles.error}>{error}</div> }

            <div className={styles.container}>
                <div className={styles.paragraphContainer}>
                    <h3>Your Partner's Details</h3>
                    <p>Accurate birth details allow us to calculate your partner's astrological chart precisely.</p>
                    <p>Your partner must be at least 18 years old.</p>
                </div>

                <form className={styles.formContainer}>
                    { currentStep === 1 && (
                        <>
                        <div className={styles.currentStep}>
                            <div>
                                <div className={styles.formGroup + " " + styles.horizontal}>
                                    <div className={styles.formGroup}>
                                        <label>Name:</label>
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            value={identity.firstName}
                                            onChange={(e) => {
                                                const updatedFirstName = e.target.value;
                                                setIdentity({ ...identity, firstName: updatedFirstName });
                                                validateStep1(updatedFirstName, identity.surName, identity.sex); // Validate step 1
                                            }}
                                            className={errors.firstName ? styles.errorField : ''}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label>&nbsp;</label>
                                        <input
                                            type="text"
                                            placeholder="Family Name"
                                            value={identity.surName}
                                            onChange={(e) => {
                                                const updatedLastName = e.target.value;
                                                setIdentity({ ...identity, surName: updatedLastName });
                                                validateStep1(identity.firstName, updatedLastName, identity.sex);
                                            }}
                                            className={errors.surName ? styles.errorField : ''}
                                        />
                                    </div>
                                </div>
                                {errors.name        && <div className={styles.error}>{errors.name}</div>}
                                {errors.firstName   && <div className={styles.error}>{errors.firstName}</div>}
                                {errors.surName     && <div className={styles.error}>{errors.surName}</div>}
                            </div>

                            <div className={styles.formGroup} style={{ marginTop: '-6em' }}>
                                <label>Sex:</label>
                                <select
                                    value={identity.sex}
                                    onChange={(e) => {
                                        const updatedSex = e.target.value;
                                        setIdentity({ ...identity, sex: updatedSex });
                                        validateStep1(identity.firstName, identity.surName, updatedSex);
                                    }}
                                    className={errors.sex ? styles.errorField : ''}
                                >
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                                {errors.sex && <div className={styles.error}>{errors.sex}</div>}
                            </div>

                            <div className={styles.horizontal}>
                                <CustomButton type="button" onClick={handleNext} disabled={!isStep1Valid}>Next</CustomButton>
                            </div>
                        </div>
                        </>
                    )}

                    { currentStep === 2 && (
                        <>
                            <div className={styles.currentStep}>
                                <div className={styles.formGroup}>
                                    <label>Place of Birth:</label>
                                    <input
                                        type="text"
                                        value={identity.pob.name}
                                        onChange={(e) => {
                                            setQuery(e.target.value);
                                            setIdentity({ ...identity, pob: { ...identity.pob, name: e.target.value } });
                                        }}
                                        className={errors.pob ? styles.errorField : ''}
                                    />
                                    {errors.pob && <div className={styles.error}>{errors.pob}</div>}
                                    <ul className={styles.suggestions}>
                                        {placeSuggestions.map((place) => (
                                            <li key={place.place_id} onClick={() => handlePlaceSelect(place)}>
                                                {place.display_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className={styles.horizontal}>
                                    <CustomButton type="button" onClick={handleBack}>Back</CustomButton>
                                    <CustomButton
                                        type="button"
                                        onClick={handleNext}
                                        disabled={!isStep2Valid}
                                    >
                                        Next
                                    </CustomButton>
                                </div>
                            </div>
                        </>
                    )}

                    { currentStep === 3 && (
                        <>
                            <div className={styles.currentStep}>
                                <div>
                                    <label>Date & Time of Birth:</label>
                                    <div className={styles.horizontal}>
                                        <DatePicker
                                            onChange={(date) => { handleUpdateBirthdate(date, timeOfBirth); }}
                                            value={dateOfBirth}
                                            required
                                            className={errors.dob ? styles.errorField : ''}
                                        />

                                        <TimePicker
                                            onChange={(time) => { handleUpdateBirthdate(dateOfBirth, time); }}
                                            value={timeOfBirth}
                                            disableClock
                                            required
                                            className={errors.dob ? styles.errorField : ''}
                                        />
                                    </div>
                                    {errors.dob && <div className={styles.error}>{errors.dob}</div>}
                                </div>

                                <div className={styles.horizontal}>
                                    <CustomButton type="button" onClick={handleBack}>Back</CustomButton>
                                    <CustomButton
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        disabled={!isStep3Valid}
                                        >
                                    Submit
                                    </CustomButton>
                                </div>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </div>
        </>
    );
};

export default PartnerInfoPage;
