import React, { useEffect, useRef}  from 'react'
import { useParams }                from 'react-router-dom'
import { Helmet }                   from 'react-helmet'
import CmsContent                   from '../components/CmsContent'
import StarsignsIndex               from '../posts/StarsignsIndex'

import Styles                       from './StarsignsPage.module.css'


const StarsignsPage = () => {

    const topRef                    = useRef(null);
    const { starSign }              = useParams() || "Index";

    // const starSigns = [
        // 'Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra',
        // 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'
    // ]

    const capitalize = (str) => {
        if (! str)
            return;
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const starsignData = {
      "Index": {
        "title": "Star Signs of the Zodiac: What does your Star Sign Say about You?",
        "metaDescription": "Explore what your star sign reveals about your personality, strengths, and destiny. Discover your unique traits and find your place in the cosmic story.",
        "slug": null
      },
      "Aries": {
        "title": "The Fiery Hearts of Aries: Unveiling the Ram's True Colors",
        "metaDescription": "Aries brings courage, determination, and a pioneering spirit. See how these traits shape its role in the zodiac.",
        "slug": "the-fiery-hearts-of-aries-unveiling-the-rams-true-colors"
      },
      "Taurus": {
        "title": "Taurus: The Steadfast Spirit of Spring",
        "metaDescription": "Taurus embodies strength, patience, and loyalty. Understand the steady influence of this earth sign.",
        "slug": "taurus-the-steadfast-spirit-of-spring"
      },
      "Gemini": {
        "title": "Gemini: The Enigmatic Twins of the Zodiac",
        "metaDescription": "Gemini brings intelligence, flexibility, and a love for connection. Learn how these traits guide its cosmic path.",
        "slug": "gemini-the-enigmatic-twins-of-the-zodiac"
      },
      "Cancer": {
        "title": "Understanding Cancer: The Emotional Nurturer of the Zodiac",
        "metaDescription": "Cancer values intuition, emotional depth, and nurturing qualities. See how these traits impact its cosmic role.",
        "slug": "understanding-cancer-the-emotional-nurturer-of-the-zodiac"
      },
      "Leo": {
        "title": "Leo: The Radiant Rulers of the Zodiac",
        "metaDescription": "Leo brings warmth, confidence, and a natural zest for life. Discover the lion’s role in the zodiac’s story.",
        "slug": "leo-the-radiant-rulers-of-the-zodiac"
      },
      "Virgo": {
        "title": "Virgo: The Architects of Purity",
        "metaDescription": "Virgo reflects order, critical thinking, and compassion. Examine the role of this thoughtful earth sign.",
        "slug": "virgo-the-architects-of-purity"
      },
      "Libra": {
        "title": "Libra: The Maestro of Harmony",
        "metaDescription": "Libra seeks fairness, beauty, and balance in all things. Understand its place in the larger cosmic design.",
        "slug": "libra-the-maestro-of-harmony"
      },
      "Scorpio": {
        "title": "Scorpio: The Enigma of the Deep",
        "metaDescription": "Scorpio values transformation, loyalty, and passion. Reflect on the influence of this powerful water sign.",
        "slug": "scorpio-the-enigma-of-the-deep"
      },
      "Sagittarius": {
        "title": "Sagittarius: The Eternal Voyager",
        "metaDescription": "Sagittarius embodies optimism, independence, and a drive for truth. Consider its path in the zodiac’s journey.",
        "slug": "sagittarius-the-eternal-voyager"
      },
      "Capricorn": {
        "title": "Capricorn: The Summit Seekers",
        "metaDescription": "Capricorn brings discipline, responsibility, and resilience. See how these traits shape its role in the zodiac.",
        "slug": "the-summit-seekers-capricorn"
      },
      "Aquarius": {
        "title": "Aquarius: Innovators of the Idealscape",
        "metaDescription": "Aquarius embodies creativity, independence, and humanitarian ideals. Understand the vision behind this air sign.",
        "slug": "aquarius-innovators-of-the-idealscape"
      },
      "Pisces": {
        "title": "Pisces: Navigators of the Heart's Oceans",
        "metaDescription": "Pisces represents empathy, imagination, and spiritual insight. Observe the role of this sensitive water sign.",
        "slug": "pisces-navigators-of-the-hearts-oceans"
      }
    };


    // const slug = starsignSlugs[starSign?.toLowerCase()];

    const starSignCapitalized = capitalize(starSign);

    const { title, metaDescription, slug } =
        starsignData[ starSignCapitalized ] || starsignData["Index"];

    const websiteUrl = slug ?
        `https://www.stargoddess.co/star-signs/${starSign.toLowerCase()}` :
        'https://www.stargoddess.co/star-signs';


    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth'});
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
        </Helmet>

        <div ref={topRef}></div>

        <div className={Styles.page}>
            <div className={Styles.content}>

                {
                    (starSign && slug) && (
                        <CmsContent type="post" slug={slug} />
                    )
                }

                {
                    (! starSign || ! slug) && (
                        <StarsignsIndex />
                    )
                }


            </div>
        </div>
        </>
    );
};

export default StarsignsPage;
