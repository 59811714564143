import React, { useState, useRef, useEffect }   from 'react'
import { Helmet }                               from 'react-helmet'
import styles                                   from './RegisterPage.module.css'
import EmailRegistrationForm                    from '../components/EmailRegistrationForm'

const RegisterPage = () => {

    const title             = 'Register for an Account |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`

    const [showInterface, setShowInterface] = useState(true);
    const topRef                            = useRef(null);

    const handleRegistrationSuccess = () => {
        setShowInterface(false);
    };

    const handleRegByAuthProvider = (provider) => {
        window.location.href =
            `${process.env.REACT_APP_API_ENDPOINT}/api/auth/${provider}`;
    };

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
            <meta name="robots" content="noindex" />
        </Helmet>

        <div ref={topRef}></div>
        <div className={styles.page}>
            <h2 className={styles.title}>Create an Account</h2>

            {showInterface ? (
                <div className={styles.interface}>
                    <div className={styles.container}>
                        <h3>Via OAuth Provider</h3>
                        <button
                            onClick={() => handleRegByAuthProvider('google')}
                            className={`${styles.oauthButton} ${styles.google}`}
                        >
                            <img src="/img/google-color.svg" width="25px" alt="Google" className={styles.icon} />
                            &nbsp;Authenticate with Google
                        </button>
                    </div>
                    <div className={styles.container}>
                        <h3>..or Via Email</h3>
                        <EmailRegistrationForm onRegistrationSuccess={handleRegistrationSuccess} />
                    </div>
                </div>
            ) : (
                <p>Please check your email to complete account registration.</p>
            )}
        </div>
        </>
    );
}

export default RegisterPage
