import React from 'react'

import LiteYouTubeEmbed from './LiteYouTubeEmbed'

import Styles from './AboutSection.module.css'


const AboutSection = () => {

    return (
    <>
    <section id="AboutSection" className={Styles.aboutSection}>

        <div className={Styles.textContainer}>
            <h2>I Am the Voice of the Stars</h2>
            <p>
                You have wandered far, seeker. The stars have whispered your name, guiding you here to the edge of the cosmos.
            </p>

            <p>
                I am the Star Goddess, keeper of celestial wisdom. Through the constellations, I reveal the secrets
                of your past, present, and the infinite paths before you.
            </p>

            <p>
                The stars do not dictate your fate; they illuminate your path. With every shift of the heavens,
                there is a message meant just for you, waiting to be discovered.
            </p>

            <p>
                So step into the light of the cosmos, and let me help you uncover the truths the universe has long held for you.
                The stars are not distant—they are a part of you.
            </p>
        </div>

        <div className={Styles.imageContainer}>
            <LiteYouTubeEmbed w="506" h="902" videoId="E1z3gYVkNM0" title="StarGoddess Astrology - About Us" />
        </div>
    </section>
    </>
    )
}



export default AboutSection;
