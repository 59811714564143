import React, { useRef, useEffect, useState }       from 'react'
import { useNavigate }                              from 'react-router-dom'
import { Helmet }                                   from 'react-helmet'

import { useAuth }                                  from '../auth/AuthContext'
import { startJourney, setJourneyData }             from '../session/journeyState'
import { continueJourney }                          from '../session/journeyLogic'
import BuyNowBox                                    from '../components/BuyNowBox'

import Styles from './YearAheadFunnelPage.module.css'


const YearAheadFunnelPage = () => {

    const { isAuthenticated }   = useAuth();
    const [consent, setConsent] = useState(false);
    const navigate              = useNavigate();
    const topRef                = useRef(null);

    const title             = 'Year-Ahead Reading | StarGoddess Astrology'
    const metaDescription   = 'Align with the cosmos and discover what the year ahead holds for you. Your Year-Ahead Reading reveals the wisdom written in the stars.'
    const websiteUrl        = `${window.location.href}`


    const handleConsentChange = (consentValue) => {
        setConsent(consentValue);
    };

    const handleBuyNowBtn = async () => {
        startJourney("year-ahead-purchase");
        setJourneyData('yearAheadNoRefundConsent', consent);
        await continueJourney(navigate, isAuthenticated);
    };

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);



    return (
    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={websiteUrl} />
        <meta property="og:url" content={websiteUrl} />
    </Helmet>

    <div ref={topRef}></div>

    <div className={Styles.page}>

        <div className={Styles.textContainer}>

            <h1>Year Ahead Astrology Reading</h1>
            <h2>Personalized Insights Just for You</h2>

            <p>
              Discover what the cosmos has in store for you with our personalized
              Year Ahead Astrology Reading.
            </p>

            <p>Gain clarity on potential opportunities,
              challenges, and key moments in your personal and professional life.
              Navigate the months ahead with confidence and foresight, tailored to your
              individual astrological profile.
            </p>

            <BuyNowBox
                handleSubmit={handleBuyNowBtn}
                onConsentChange={handleConsentChange}
                price="14.99*"
                normalPrice="19.99" />

        </div>

        <div className={Styles.imageContainer}>
            <img src="/img/year-ahead-crystal-ball.jpg"
                 alt="your year ahead astrology reading"
                 className={Styles.image}
                 loading="lazy" />
        </div>
    </div>
    </>
    );
};

export default YearAheadFunnelPage
