import React, { useState }      from 'react'
import { Helmet }               from 'react-helmet'
import axios                    from 'axios'
import HCaptcha                 from '@hcaptcha/react-hcaptcha'
import Spinner                  from '../components/Spinner'
// eslint-disable-next-line
import { b64Encode, b64Decode } from '../utils/utils'
import styles                   from './RequestPasswdResetPage.module.css'

const RequestPasswdResetPage = () => {

    const [email, setEmail]                 = useState('');
    const [isValid, setIsValid]             = useState(false);
    const [isLoading, setIsLoading]         = useState(false);
    const [submitted, setSubmitted]         = useState(false);
    const [captchaValid, setCaptchaValid]   = useState(false);
    const [errorMessage, setErrorMessage]   = useState('');

    const title             = 'Reset your Password |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`


    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsValid(validateEmail(inputEmail));
        setErrorMessage('');
    };

    const handleVerificationSuccess = (token) => {
        setCaptchaValid(true);
        setErrorMessage('');
    };

    const handleVerificationError = (error) => {
        setCaptchaValid(false);
        setErrorMessage('Failed to verify captcha, please try again.');
        console.error('hCaptcha Error:', error);
    };

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (isValid && captchaValid && !isLoading)
        {
            setIsLoading(true);

            try
            {
                const url       = `${process.env.REACT_APP_API_ENDPOINT}/api/auth/request-pw-reset`;
                await axios.post(url, { data: b64Encode(email) });

                setSubmitted(true);
            }
            catch (error)
            {
                console.error('Failed to send request:', error);
                setErrorMessage('Failed to process your request, please try again later.');
            }
            finally
            {
                setIsLoading(false);
            }
        }
        else if (! captchaValid)
        {
            setErrorMessage('Please complete the captcha to proceed.');
        }
    };

    if (submitted)
    {
        return (
            <>

            <Helmet>
                <title>{title}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href={websiteUrl} />
                <meta property="og:url" content={websiteUrl} />
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className={styles.page}>
                <div className={styles.container}>
                    <h2>Password Reset Requested</h2>
                    <p>Please check your email to continue the password reset process.</p>
                </div>
            </div>
            </>
        );
    }

    return (
        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
            <meta name="robots" content="noindex" />
        </Helmet>

        <div className={styles.page}>
            <div className={styles.container}>
                <h2>Reset Password</h2>

                <form onSubmit={handleSubmit} className={styles.form}>

                    <p>Please enter your email address to receive a link to reset your password.</p>

                    { errorMessage && (
                        <>
                        <div className={styles.error}>
                            {errorMessage}
                        </div>
                        </>
                    )}

                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                        className={styles.input}
                        disabled={isLoading}
                    />

                    <HCaptcha
                        sitekey={`${b64Decode(process.env.REACT_APP_HCAPTCHA_SITE_KEY)}`}
                        onVerify={handleVerificationSuccess}
                        onError={handleVerificationError}
                    />

                    <button
                        type="submit"
                        disabled={!isValid || !captchaValid || isLoading}
                        className={isValid && captchaValid && !isLoading ? styles.button : styles.buttonDisabled}
                    >
                        { isLoading ? <Spinner sizeClass="small" /> : 'Submit'}
                    </button>
                </form>
            </div>
        </div>
        </>
    );
};

export default RequestPasswdResetPage
