import React, { useState }      from 'react'
import { Link }                 from 'react-router-dom'
import axios                    from 'axios'
import HCaptcha                 from '@hcaptcha/react-hcaptcha'
import styles                   from './EmailSigninForm.module.css'
// eslint-disable-next-line
import { b64Encode, b64Decode } from '../utils/utils'

const EmailSigninForm = () => {

    const [email, setEmail]             = useState('');
    const [password, setPassword]       = useState('');
    const [isVerified, setIsVerified]   = useState(false);
    const [error, setError]             = useState('');

    const handleEmailChange     = (e) => setEmail(e.target.value);
    const handlePasswordChange  = (e) => setPassword(e.target.value);

    const handleVerificationSuccess = (token, ekey) => {
        setIsVerified(true);
        setError('');  // Clear any existing errors
    };

    const handleVerificationError = (err) => {
        console.log('HCaptcha Error:', err);
        setIsVerified(false);
        setError('Captcha verification failed. Please try again.');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (! isVerified) {
            setError('Please complete the hCaptcha');
            return;
        }

        let response;

        try
        {
            const url = `${process.env.REACT_APP_API_ENDPOINT}/api/auth/sbm`;

            response = await axios.post(url, {
                data: b64Encode(JSON.stringify({ email, password }))
            });

            if (response?.status === 200 && response?.data?.authCode)
            {
                window.location.href =
                    `${process.env.REACT_APP_BASE_URL}/auth-success?authCode=${response.data.authCode}`;
            }
            else
            {
                setError('Signin failed');
            }
        }
        catch (err)
        {
            switch (response?.status)
            {
            case 404:
                setError('Signin failed');
                break;

            case 500:
                setError('Internal server error');
                break;

            default:
                console.error(`Exception in handleSubmit:\n${err}\n\n`);
                break;
            }
        }
    };

    return (
        <>
        <form className={styles.form}>

            <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={handleEmailChange}
                className={styles.inputField}
            />

            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                className={styles.inputField}
            />

            <HCaptcha
                sitekey={`${b64Decode(process.env.REACT_APP_HCAPTCHA_SITE_KEY)}`}
                onVerify={handleVerificationSuccess}
                onError={handleVerificationError}
            />

            <button onClick={handleSubmit} disabled={!isVerified} className={styles.blueButton}>
                Sign In
            </button>

            <ul>
                <li> <Link to="/register">register</Link> </li>
                <li> <Link to="/request-reset-pass">reset password</Link> </li>
            </ul>

            { error && <div className={styles.error}>{error}</div> }
        </form>
        </>
    );
};

export default EmailSigninForm;

