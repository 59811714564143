import React        from 'react'
import { Link }     from 'react-router-dom'
import AccordionFAQ from './AccordionFAQ'

import Styles from './CommonQuestionsSection.module.css'


const CommonQuestionsSection = () => {

    const faqTitle = 'Top Astrology Questions Answered for Beginners';

    const faqs = [
        {
            question: 'What is Western Astrology?',
            answer: (
                <>
                    <p>
                     Western astrology is an ancient system of divination that interprets the movements and positions of celestial bodies, such as the Sun, Moon, and planets, to understand their influence on human affairs and natural events. This system is deeply rooted in Babylonian, Egyptian, and Hellenistic traditions, which eventually developed into the zodiac-based model that we use today. The Western zodiac consists of 12 signs, each corresponding to a specific time of year and representing distinct personality traits and life themes.
                    </p>
                    <p>
                    At its core, Western astrology revolves around creating a <strong>birth chart</strong>, also known as a <strong>natal chart</strong>, which maps the sky at the exact time and place of your birth. The positions of the celestial bodies in relation to the zodiac signs and houses provide a unique cosmic blueprint of your strengths, challenges, and life purpose. Through astrological interpretation, Western astrology aims to offer insight, guidance, and self-awareness.
                    </p>
                </>
            )
        },
        {
            question: 'What are the 12 Zodiac Signs and Their Dates?',
            answer: (
                <>
                    <p>
                    The 12 zodiac signs are fundamental to Western astrology, each representing specific personality traits and tendencies. They are divided into four elements: Fire, Earth, Air, and Water. The signs and their corresponding dates are:
                    </p>
                    <h3>Fire Signs</h3>
                    <ul>
                        <li>Aries (March 21 - April 19)</li>
                        <li>Leo (July 23 - August 22)</li>
                        <li>Sagittarius (November 22 - December 21)</li>
                    </ul>

                    <h3>Earth Signs</h3>
                    <ul>
                        <li>Taurus (April 20 - May 20)</li>
                        <li>Virgo (August 23 - September 22)</li>
                        <li>Capricorn (December 22 - January 19)</li>
                    </ul>

                    <h3>Air Signs</h3>
                    <ul>
                        <li>Gemini (May 21 - June 20)</li>
                        <li>Libra (September 23 - October 22)</li>
                        <li>Aquarius (January 20 - February 18)</li>
                    </ul>

                    <h3>Water Signs</h3>
                    <ul>
                        <li>Cancer (June 21 - July 22)</li>
                        <li>Scorpio (October 23 - November 21)</li>
                        <li>Pisces (February 19 - March 20)</li>
                    </ul>

                </>
            )
        },
        {
            question: 'What is a Birth Chart and How is it Used?',
            answer: (
                <>
                    <p>
                        A birth chart, also known as a <strong>natal chart</strong>, is a detailed snapshot of the sky at the exact time and location of your birth. It maps the positions of the Sun, Moon, planets, and the 12 astrological houses, each of which represents different aspects of life such as career, relationships, and personal growth. These planetary positions in relation to the zodiac signs reveal insights into your personality, emotional nature, strengths, and challenges.
                    </p>
                    <p>
                        Astrologers interpret the birth chart to explore key themes and dynamics in your life, helping you better understand your purpose, relationships, and potential paths forward. By analyzing aspects like the Sun sign, Moon sign, and Ascendant, a birth chart offers a comprehensive view of who you are, your core motivations, and how you interact with the world. It serves as a guide for personal reflection, growth, and decision-making.
                    </p>
                </>
            )
        },
        {
            question: 'What are Sun, Moon, and Rising Signs?',
            answer: (
                <>
                    <p>
                        In astrology, the <strong>Sun sign</strong>, <strong>Moon sign</strong>, and <strong>Rising sign</strong> (or Ascendant) are the three most significant components of your astrological profile. Your Sun sign represents your core identity, ego, and the essence of who you are at a deeper level. It reflects your conscious self, your purpose, and the qualities that drive you forward in life.
                    </p>
                    <p>
                        Your Moon sign, on the other hand, governs your emotional nature, instincts, and inner world. It reveals how you process emotions and respond to personal experiences. The Rising sign, also known as the Ascendant, is the mask you wear to the outside world. It indicates how others perceive you and how you present yourself in new situations.
                    </p>
                    <p>
                        Together, these three signs create a well-rounded picture of your personality, blending your inner emotional self, outer behavior, and core essence.
                    </p>
                </>
            )
        },
        {
            question: 'How Do Planetary Transits Affect Us?',
            answer: (
                <>
                    <p>
                        Planetary transits refer to the continuous movement of planets through the zodiac and their interaction with the positions of the planets in your birth chart. As planets move, they form angles (called aspects) to the planetary positions at your birth, which can trigger significant events, emotions, or shifts in various areas of your life, such as relationships, career, or personal growth.
                    </p>
                    <p>
                        Transits are seen as cosmic triggers that activate or influence specific themes in your life, depending on which planets and areas of your birth chart are involved. For example, a challenging Saturn transit might bring lessons of discipline and patience, while a Jupiter transit could bring opportunities for growth and expansion. Astrologers study these transits to provide insights on timing for important decisions and to help individuals navigate periods of change or challenge with greater understanding.
                    </p>
                </>
            )
        },
        {
            question: 'What is a Retrograde and What Does It Mean?',
            answer: (
                <>
                    <p>
                        A retrograde occurs when a planet appears to move backward in its orbit from our perspective on Earth. This optical illusion happens due to differences in the orbital speeds of Earth and other planets. In astrology, retrogrades are seen as periods where the energy of the retrograding planet is turned inward, leading to reflection, reconsideration, or even disruption in the areas of life that planet governs.
                    </p>
                    <p>
                        The most well-known example is <strong>Mercury retrograde</strong>, which is associated with communication issues, travel delays, and technological mishaps. Other planets, when in retrograde, can affect areas such as relationships, finances, or personal growth, depending on the planet's domain. Astrologers view retrogrades as opportunities to reassess, review, and recalibrate plans rather than fear or avoid them. Navigating a retrograde with awareness can lead to personal growth and clarity once the planet resumes its forward motion.
                    </p>
                </>
            )
        },
        {
            question: 'How Does Compatibility Work in Astrology?',
            answer: (
                <>
                    <p>
                        Astrological compatibility, often referred to as synastry, examines how the planetary positions in two individuals' birth charts interact with one another. It looks at key aspects such as Sun signs, Moon signs, Venus, and Mars, as well as the angles (called aspects) that form between planets in each chart. These planetary interactions reveal insights into relationship dynamics, emotional compatibility, communication styles, and potential areas of tension or harmony.
                    </p>
                    <p>
                        A synastry chart offers a deeper understanding of the strengths and challenges within a relationship, helping couples navigate their differences and appreciate their unique bond. By understanding how two charts align, astrologers can provide guidance on how partners can grow together. For a personalized love compatibility reading, explore our exclusive offering at <Link to='/love-horoscope-reading'>StarGoddess Love Compatibility Reading</Link>. This detailed analysis delves into the dynamics of your relationship, offering insights into potential challenges and growth opportunities.
                    </p>
                </>
            )
        },
        {
            question: 'What is the Difference Between Western and Vedic Astrology?',
            answer: (
                <>
                    <p>
                        Western astrology is based on the <strong>tropical zodiac</strong>, which aligns the signs with the seasons, starting with Aries at the Spring Equinox. Vedic astrology, or Jyotish, uses the <strong>sidereal zodiac</strong>, aligning signs with their current positions relative to the constellations. This results in a shift of nearly one entire sign between the two systems. Vedic astrology places a greater emphasis on Moon signs and predictive techniques like dashas.
                    </p>
                </>
            )
        },
        {
            question: 'How Can I Use Astrology for Personal Growth?',
            answer: (
                <>
                    <p>
                        The primary difference between Western astrology and Vedic astrology (also known as Jyotish) lies in the zodiac system they use. Western astrology is based on the <strong>tropical zodiac</strong>, which aligns the zodiac signs with the seasons. It begins with Aries at the Spring Equinox and focuses on the Sun sign to interpret personality and life events. Western astrology also emphasizes psychological insights and personal growth.
                    </p>
                    <p>
                        Vedic astrology, on the other hand, uses the <strong>sidereal zodiac</strong>, which aligns the zodiac signs with the current position of the constellations. This system often results in a shift of almost one entire sign between the two systems. Vedic astrology places greater emphasis on the Moon sign and the planetary periods (called dashas), which are used to make predictions about key life events. Both systems offer valuable insights, but they differ in their approaches and interpretations.
                    </p>
                </>
            )
        },
        {
            question: 'Is It Possible to Interpret My Own Astrology Chart?',
            answer: (
                <>
                    <p>
                        While it is possible to learn how to interpret your own astrology chart, it is generally not recommended to read your own chart without guidance. The reason for this is that self-interpretation can often be clouded by personal biases, making it difficult to remain objective. A trained astrologer can offer a more accurate and balanced reading by bringing in their expertise and an outsider's perspective.
                    </p>
                    <p>
                        However, if you are interested in learning how to interpret astrology charts for yourself and others, there are excellent resources available to help you understand the fundamentals of astrology. One such resource, which offers a step-by-step guide to mastering the art of astrology is "The Easiest Way to Learn Astrology, Ever!", which can be found on Amazon, and is a fine guide to start your journey towards reading and understanding astrological charts.
                    </p>
                </>
            )
        }
    ];


    const tarotTitle = 'Commonly Asked Tarot Questions and Answers';

    const tarotFaqs = [
        {
            question: 'What is Tarot and how does it work?',
            answer: (
                <>
                    <p>
                        Tarot is a powerful tool for divination and self-reflection, consisting of a deck of 78 cards, each rich
                        with symbolic imagery. The deck is divided into two parts: the Major Arcana, which represents significant
                        life events and spiritual lessons, and the Minor Arcana, which focuses on day-to-day challenges and experiences.
                    </p>
                    <p>
                        During a reading, the tarot reader shuffles and arranges the cards into a particular layout, or "spread,"
                        based on the type of question or issue being addressed. The meaning of each card is influenced not only by
                        its individual symbolism but also by its position in the spread and its relationship to the surrounding cards.
                    </p>
                    <p>
                        The goal of a tarot reading is to tap into universal energies or subconscious guidance, providing insights
                        that help navigate life’s challenges, offer clarity, or reveal hidden truths.
                    </p>
                </>
            )
        },
        {
            question: 'Can I do a tarot reading for myself?',
            answer: (
                <>
                    <p>
                        While it is possible to perform a tarot reading for yourself, we generally recommend having someone else read for you.
                        When you read your own cards, personal emotions and biases can interfere with the interpretation, leading to less clarity.
                        A professional or experienced reader can offer an objective perspective, ensuring a more accurate and insightful reading.
                    </p>
                    <p>
                        If you're interested in learning how to perform tarot readings for yourself and others,&nbsp;
                        Tarot Journal Workbook: The Journey of the Fool, also found on Amazon, which provides an
                        excellent guide for mastering tarot card interpretation. Whether you're a beginner or looking to deepen your skills, this
                        book offers everything you need to become a confident tarot reader. Pick up a new hobby, and start developing your
                        spiritual gifts through tarot today!
                    </p>
                    <p>
                        Oh, and don't forget to pick up a Tarot Deck so you can practise on your friends!
                    </p>
                </>
            )
        },
        {
            question: 'What does it mean when a card appears reversed?',
            answer: (
                <>
                    <p>
                        A reversed tarot card is one that appears upside-down in a spread, and its interpretation often shifts from the card's
                        traditional meaning. Reversals can indicate delays, challenges, or blocked energy related to the card's typical message.
                        For instance, a card that represents progress or success when upright might suggest obstacles, setbacks, or missed
                        opportunities when reversed.  While not all readers use reversals, they can add depth to a reading.
                    </p>
                    <p>
                        Reversed cards are often viewed as opportunities to reflect on deeper layers of a situation or to address underlying
                        issues that may not be immediately visible. Some readers choose not to interpret reversals, while others find them
                        essential for providing a more nuanced and detailed reading. By incorporating reversed meanings, a tarot reading can offer
                        greater insight into challenges or areas in need of healing, adding depth and complexity to the overall interpretation.
                    </p>
                </>
            )
        },
        {
            question: 'How often can I ask the same question in a tarot reading?',
            answer: (
                <>
                    <p>
                        It’s best to avoid asking the same question too frequently, as tarot works by providing guidance based on your current
                        situation and energy.
                    </p>
                    <p>
                        If you ask the same question repeatedly, especially within a short period, the readings may become less clear or even
                        contradictory. Instead, try to wait until your circumstances have changed or you've gained new insights before revisiting
                        the same question. Tarot is most helpful when approached with patience and an open mind, allowing time for the guidance
                        to unfold.
                    </p>
                </>
            )
        },
        {
            question: 'What are tarot spreads?',
            answer: (
                <>
                    <p>
                        Tarot spreads refer to the specific layouts in which tarot cards are drawn and arranged during a reading.
                        The pattern or placement of the cards within a spread plays a significant role in how the reader interprets
                        their meaning. Each position in a spread represents a particular aspect of the question or situation being
                        explored, offering insights into the past, present, future, or specific areas like relationships or career.
                    </p>
                    <p>
                        Some spreads are simple, such as the popular Three-Card spread, which provides insight into past, present,
                        and future influences. Others, like the Celtic Cross, are more complex and can delve deeply into multiple
                        facets of a situation. The type of spread chosen often depends on the question being asked, as different
                        spreads are designed to answer specific inquiries or provide more focused guidance on a particular area of life.
                    </p>
                </>
            )
        },
        {
            question: 'Do tarot cards predict the future?',
            answer: (
                <>
                    <p>
                        Tarot cards do not predict the future with absolute certainty. Instead, they offer insights into potential
                        outcomes by reflecting the current energies, influences, and choices surrounding a situation. The future
                        is not fixed, and tarot readings emphasize the fluidity of life, where personal decisions and external factors
                        can shift the direction of events.
                    </p>
                    <p>
                        Rather than providing a definitive prediction, tarot serves as a guide to help individuals navigate their
                        options and make informed decisions. It can highlight opportunities, challenges, and potential pathways,
                        but the ultimate outcome remains shaped by personal actions and circumstances. Tarot readings encourage
                        self-reflection and deeper awareness, empowering individuals to influence their future by understanding
                        the energies at play. It’s a tool for gaining clarity and foresight, but not a rigid map of what’s to come.
                    </p>
                </>
            )
        },
        {
            question: 'What is the significance of the Death card?',
            answer: (
                <>
                    <p>
                        Despite its name and dark imagery, the Death card in tarot rarely signifies physical death. Instead, it symbolizes
                        transformation, the end of one cycle, and the beginning of a new one. The Death card is about shedding old patterns,
                        habits, or situations that no longer serve you, making way for fresh opportunities and growth. It represents a
                        necessary phase of transition, often leading to personal renewal and transformation.
                    </p>
                    <p>
                        The card encourages acceptance of change as a natural part of life’s cycle, urging you to let go of attachments to the
                        past so that something new can emerge. Whether it’s a relationship, a job, or a mindset, the Death card indicates that
                        it's time to embrace endings in order to move forward. Far from a card to fear, it is a powerful reminder of life's
                        constant evolution and the potential for positive change.
                    </p>
                </>
            )
        },
        {
            question: 'How do you cleanse or reset your tarot deck?',
            answer: (
                <>
                    <p>
                        Many tarot readers cleanse or reset their decks regularly to clear away any residual energies from previous readings.
                        This practice helps refresh the deck, ensuring it’s ready for future use with a neutral energy. Common methods of
                        cleansing include passing the cards through the smoke of incense, such as sage or palo santo, which is believed to
                        purify and uplift the energy.
                    </p>
                    <p>
                        Other methods involve placing the cards in direct sunlight or under the light of the full moon, which many believe
                        helps recharge the deck with natural energy. Some readers also use crystals, such as clear quartz or selenite, by
                        placing them on or near the deck to absorb any lingering energy. Cleansing your tarot deck is a personal ritual,
                        and it can help create a clear and focused space for intuitive readings and spiritual work.
                    </p>
                </>
            )
        },
        {
            question: 'Is tarot connected to astrology or numerology?',
            answer: (
                <>
                    <p>
                        While tarot is a standalone divination tool, it is often used in conjunction with astrology and numerology to provide
                        deeper layers of insight. Many tarot readers blend these practices, as they share symbolic systems and can complement
                        one another in readings. For example, each tarot card is associated with specific astrological signs or planets, and
                        numerology helps interpret the significance of card numbers within a reading.
                    </p>
                    <p>
                        However, you do not need to be knowledgeable in astrology or numerology to effectively read tarot. Tarot can be interpreted
                        independently based on the imagery, symbolism, and intuitive guidance that comes from the cards themselves. Combining
                        astrology, numerology, and tarot can enrich the reading and provide more holistic insights into a person’s life, but it’s
                        entirely up to the reader’s preference and expertise whether they choose to incorporate these systems.
                    </p>
                </>
            )
        },
        {
            question: 'Are tarot cards dangerous or evil?',
            answer: (
                <>
                    <p>
                        Tarot cards are not inherently dangerous or evil. They are simply a tool used for introspection, self-reflection, and
                        guidance, similar to practices like meditation or journaling. Tarot’s purpose is to help individuals explore their
                        emotions, challenges, and life paths by providing a symbolic framework for understanding their current circumstances.
                        The power of tarot lies in the interpretation and intentions of the reader and querent, rather than the cards themselves.
                    </p>
                    <p>
                        How tarot is used depends entirely on the mindset and energy of those involved. As with any tool, it is important to
                        approach tarot with respect and an open mind, focusing on the positive intention to gain clarity or personal growth.
                        Tarot is neither inherently mystical nor supernatural; it’s a method for tapping into deeper layers of consciousness and
                        understanding. When used thoughtfully, it can be a valuable tool for personal development.
                    </p>
                </>
            )
        }
    ];


    return (
        <>
        <section id="commonQuestions">

            <div className={Styles.row}>

                <div className={Styles.column}>
                    <div className={Styles.commonQuestions}>
                        <AccordionFAQ topicName="AstrologyCommonQuestions" title={faqTitle} faqs={faqs} />
                    </div>
                </div>

                <div className={Styles.column}>
                    <div className={Styles.commonQuestions}>
                        <AccordionFAQ topicName="TarotCommonQuestions" title={tarotTitle} faqs={tarotFaqs} />
                    </div>
                </div>

            </div>

        </section>
        </>
    );
};

export default CommonQuestionsSection;
