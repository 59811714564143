import React            from 'react'
import { Helmet }       from 'react-helmet'
import styles           from './OrderCanceledPage.module.css'


const OrderCanceledPage = () => {


    const title             = 'Order Canceled | StarGoddess Astrology'
    const websiteUrl        = `${window.location.href}`
    const metaDescription   = 'Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights'


    return (
        <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
        </Helmet>

        <div id="page"></div>

        <div className={styles.container}>
            <div className={styles.column}>
                <h1 className={styles.title}>Order Canceled</h1>
                <h2 className={styles.subtitle}>Your order has been canceled</h2>
                <p className={styles.paragraph}>
                    We regret to inform you that your order has been canceled. If you believe this is an error or if you have any questions,
                    please contact our support team. We are here to help and ensure that you have a positive experience with our service.
                </p>
            </div>
        </div>
        </>
    );
};

export default OrderCanceledPage
