import create       from 'zustand'
import { persist }  from 'zustand/middleware'


const useSubscriptionStore = create(
  persist(
    (set, get) => ({
      isSubscribed:           false,
      setSubscriptionStatus:  (status) => set({ isSubscribed: status }),
    }),

    {
      name:               'subscription-store',
      getStorage:         () => localStorage
    }
  )
);


export default useSubscriptionStore;
