import React, { useState, useEffect }   from 'react'
import { Helmet }                       from 'react-helmet'
import { useLocation/*, useNavigate*/ } from 'react-router-dom'
import { HashLink }                     from 'react-router-hash-link'
import axiosInstance                    from '../utils/axiosInstance'
import styles                           from './OrderCompletePage.module.css'
//import { b64Decode }                    from '../utils/utils'

const OrderCompletePage = () => {

    const location                          = useLocation();
    const [orderSummary, setOrderSummary]   = useState(null);
    const [loading, setLoading]             = useState(true);
    //const navigate                          = useNavigate();

    const title             = 'Order Complete, thank you! | StarGoddess Astrology'
    const websiteUrl        = `${window.location.href}`
    const metaDescription   = 'Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights'


    useEffect(() => {
        const fetchOrderSummary = async (oId) => {
            try
            {
                const response =
                    await axiosInstance.get(`/api/shop/orderSummary?oid=${oId}`);

                setOrderSummary(response.data);
            }
            catch (err)
            {
                console.error('⚠ Exception while fetching order summary:\n', err, '\n\n');
            }
            finally
            {
                setLoading(false);
            }
        };

        const params    = new URLSearchParams(location.search);
        const oId       = params.get('oId');

        if (oId && ! orderSummary) {
            fetchOrderSummary(oId);
        }

    }, [loading, orderSummary, location.search]);

    return (
        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={websiteUrl} />
            <meta property="og:url" content={websiteUrl} />
        </Helmet>

        <div id="page"></div>

        <div className={styles.container}>
            <div className={styles.column}>
                <h1 className={styles.title}>Thank You for Your Order!</h1>
                <h2 className={styles.subtitle}>We Appreciate Your Support</h2>

                { orderSummary ? (
                        <>
                        <div className={styles.orderDetails}>
                            <h3>Order Summary</h3>
                            <p><strong>Product Name:</strong> {orderSummary.productInfo?.name}</p>
                            <p><strong>Description:</strong> {orderSummary.productInfo?.description}</p>
                            <p><strong>Price:</strong> ${orderSummary.productInfo?.price_usd?.toFixed(2)}</p>
                        </div>
                        </>
                    ) : (
                        <p>Loading order summary...</p>
                    )}

                <p className={styles.paragraph}>
                    Your digital product should be ready within the next four hours.
                    Please check your <HashLink smooth to="/dashboard/orderHistory">dashboard</HashLink> for order status updates.
                    If you have any questions, please contact our support team.
                </p>
            </div>
        </div>
        </>
    );
};

export default OrderCompletePage;
